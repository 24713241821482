export const contractAddress_list = {
  factionsagentsbox: "0xb704e08173f23f52e86a08b99e26ae9ec98c9a7d",
  factionsagents: "0xcafb20935c16db1d4917027b70cb690bdaec89f9",
  corelootboxv2: "0x20dd967458922cDC21b16F69e043c7C918bcE8eF",
  skinlootboxv1: "0xbcDEB62e65666A13b4dF9fab76258b3164Da18bd",
  skinlootboxv2: "0xdb2fe592e2872eb7a63f308489b229b8b858273c",
  skinlootboxv3: "0x038e70f5d0597fbe4b0f24c2acd93759da59c35f",
  skinlootbox_bredmadness202403: "0x645b5e53f9564d155a460178f41473aefb68fc02",
  skinlootbox_carweth: "0x4de7dbd961aeacbeff34964dc6051b70b3269f22",
  fbike: "0xce8090de88bba13d3cea5d73f8baf1f1c1a61b16",
  core: "0xce8090de88bba13d3cea5d73f8baf1f1c1a61b16",
  fbikeskin: "0xCD0783C0e2b0A68A64ba7c5e0F99097945397cF3",
  skinlootboxv1_101: "0x459698b869a671ecdF7c7714B101eb8B8891C2C5",
  skingame: "0x805D915B0fa34AABf21Ba68800782995817563AF",
  skingamead: "0x00a64884B1d5098dEE5695499034a60433FE54EF",
  skinlootboxxmas2023: "0x2e5e5588ed7a60b1060dc5d13101a50eb17299e2",
  dez: "0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e",
  // bikeburner: "0x03ccbddbabca334df4fe4d52b1219009309cdd9b",
  bikeburner: "0x621dcdabc202c1917ba4d403632041b7e4a14b3e",
  prizebox: "0x59768e3a04b0598b128ba5039f24b7bd61cbb24a",
  tp_giftcard: "0x7541c3ce9720608075a7adc20daa4b563afcebc3",
  skinsdirectv1: "0xd49cb6b7c64f4b767f116504650cfe1688a7a75e",
  seasonpass: "0x6f37b7557d611e7034715d7773a7ce0d43e60080",
  splicing: "0x792a3A3539A314f4FaC63F6fcc546c3ef9fFC8CE",
  weth: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  WETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
  dez: "0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e",
  DEZ: "0xdc4f4ed9872571d5ec8986a502a0d88f3a175f1e",
  racestake: "0x60dbd31fAefa2Bf87e85e7582e389DC134eDa2b1",
  racestakev02: "0x1DfE57D9aC38E3168ab920f050934667d32Ba433",
  racestakev03dez: "0x3e4FC7D4161c6A5B8667D55bf0F71aad03095D3F",
  racestakev04bgc: "0xa5a7c62d5ec3016a8162cd06b0377ad7088f4a17",
  BGC: "0x8226fe9615d39fa09f389860ecd579b5a37e9c35",
  bgc: "0x8226fe9615d39fa09f389860ecd579b5a37e9c35",
  bikecredits: "0x8226fe9615d39fa09f389860ecd579b5a37e9c35",
  sgc: "0xF81f84b068e19a72D84313Fd5F5E8d02eece8B05",
  skincredits: "0xF81f84b068e19a72D84313Fd5F5E8d02eece8B05",
  stakingv1: "0x67977609047c6a4356a723ba1976a78b165b2e82",
  dnatokenconverter: "0x82ced73aee55d6d7931ad27cf8b5cb4bcfe25fcc",
  raceautofiller: "0xc69bbf892275ffa6450a7b006d48c6d12dd0e8e5",
  "weth-mock01": "0x7ae5e11b113b2d8962a120c4214566980f4493c3",
  lc01: "0x0fba3b7e384c7127f29198c3752e5af4bf70b7fb",
  lc02: "0x34002a3cdb1a6e56bd8e2530526d8b664e2c3f17",
  lc: "0x02d115fe8404b9ffe5ca8f19db392bbeea1cb282",
  market_weth: "0x18bea2aef6a3fef912fe7458f527d6dfbe5e98ab",
  market_dez: "0x385131f3d2985974b2087d4ef1bd41de580fd6f9",
};
