import React, { useEffect, useMemo, useState } from "react";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import _, { get } from "lodash";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  toeth,
  tofeth,
} from "../utils/utils.js";
import moment from "moment";
import {
  polymaintxnlink,
  q_skinxmas_info,
  q_skinxmas_txnque_add,
  q_skinxmas_txns,
  qiserr,
  qissuccess,
  qissuccesss,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import {
  Card,
  Img,
  InpText,
  Tag,
  TokenIcon,
  tablecn,
} from "../components/utilityComps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClose,
  faLock,
  faRefresh,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import { twMerge } from "tailwind-merge";
import { metamaskimg, polychainimg } from "../utils/links.js";
import { Helmet } from "react-helmet-async";
import { Loader01c } from "../components/anims.js";
import { tokdecn, useAppContext } from "../App.js";
import { SkinCard } from "./Skins.js";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import { contractAddress_list } from "../contracts/contracts.js";
import { mm_asset_signer } from "../contracts/contract_funcs.js";
import { SkinLootbox_xmax2023 } from "../contracts/SkinLootbox_xmax2023/SkinLootbox_xmax2023.js";

const SXContext = React.createContext({});
export const useSXContext = () => React.useContext(SXContext);

const xmasbannerimg =
  "https://cdn.pixabay.com/photo/2017/11/07/20/43/christmas-tree-2928142_1280.jpg";

const boxtypes = _.chain([
  [1, "Grinder", "#464C79", "imgs/sksc02.png"],
  [2, "Qualified", "#D07C00", "imgs/sksc05.png"],
])
  .map((e) => {
    let [boxtype, series, acc_color, img] = e;
    return { boxtype, series, acc_color, img };
  })
  .keyBy("boxtype")
  .value();

const boxtypes_n = _.values(boxtypes).length;

const BuyBoxBtn = ({ boxtheme, token, boxtype }) => {
  const mmcon = useMetaMaskContext();
  const vault = mmcon.mmaccount;

  const { qo_txns, info } = useSXContext();

  const on_buyclick = async () => {};

  const [popup, set_popup] = useState(false);
  const [qty, set_qty] = useState(1);
  const [err, set_err] = useState(null);
  const [msg, set_msg] = useState(null);

  const boxprice = getv(info, `pricemap.${token}.${boxtype}`);
  const _tokdecn = token == "WETH" ? 4 : 0;

  useEffect(() => {
    try {
      let v = qty;
      v = parseInt(v);
      if (nils(v)) throw new Error("enter valid quantity");
      if (!_.inRange(v, 1, 25.001))
        throw new Error("min:1 max:25 boxes can be bought at a time");
      else set_err(null);
    } catch (err) {
      set_err(err.message);
    }
  }, [qty]);

  const [loading, set_loading] = useState(false);
  const clickpayment = async () => {
    try {
      set_loading(true);
      let price = getv(info, `pricemap.${token}.${boxtype}`);
      const topay = parseFloat(boxprice) * qty;

      const sxcon = await SkinLootbox_xmax2023.get_contract();
      const tokenaddress =
        contractAddress_list[
          token == "WETH" ? "weth" : token == "DEZ" ? "dez" : null
        ];
      if (nils(tokenaddress)) throw new Error("invalid token");
      const tokenContract = await mm_asset_signer(null, 20, tokenaddress);

      let bal = await tokenContract.balanceOf(vault);
      bal = tofeth(bal);
      if (parseFloat(bal) < topay) throw new Error("not enough balance");

      let alw = await tokenContract.allowance(vault, sxcon.contractAddress);
      alw = tofeth(alw);
      if (parseFloat(alw) < topay) {
        let tx = await tokenContract.approve(
          sxcon.contractAddress,
          toeth(topay),
        );
        await tx.wait();
        await cdelay(2000);
      }

      try {
        let resp = await sxcon.buyLootbox(qty, token, boxtype);
        console.log(resp);
        let hash = resp.hash;
        set_msg(`Transaction Successful`);
        set_loading(false);

        let o = { vault, hash, boxtype, qty };
        await q_skinxmas_txnque_add(o).queryFn();
      } catch (err) {
        let txt = err.reason;
        if (txt && txt.length > 40) {
          txt = txt.slice(0, 40) + "...";
        }
        throw new Error(`Metamask: ${txt}`);
      }

      set_err(null);

      await cdelay(2000);

      /*   setTimeout(() => {
        upd_closed_n();
      }, 5 * 1e3); */

      setTimeout(qo_txns.refetch, 60 * 1e3);
      // setTimeout(balance_update, 20 * 1e3);

      set_popup(false);
    } catch (err) {
      console.log(err);
      set_err(err.message);
      await cdelay(2000);
      set_loading(false);
    }
  };

  const tokacccolor = token == "WETH" ? "#AA336A" : "#06DEC3";
  const toktextcn = token == "WETH" ? "text-pink-400" : "text-acc0";

  const closepopup = () => {
    set_popup(false);
    set_err(null);
    set_msg(null);
  };

  return (
    <div className="w-full">
      <div className={twMerge("w-full fc-cc", toktextcn)}>
        <TokenIcon className={""} size="md" token={token} />

        {mmcon.auth && (
          <>
            <Tag
              onClick={() => {
                set_popup(true);
              }}
              style={{
                backgroundColor: `${tokacccolor}40`,
                border: `2px solid ${tokacccolor}`,
              }}
              className={twMerge("fr-sc text-white")}
            >
              Buy Now
            </Tag>
          </>
        )}
      </div>

      <PopUp openstate={popup} overlayclose={false}>
        <div className="fr-sc">
          <div className="flex-1"></div>
          <PopupCloseBtn closepopup={closepopup} />
        </div>
        <Card className={"bg-dark w-[30rem] max-w-[90vw]"}>
          <div className="fr-cc"></div>
          {!nils(err) && (
            <p className="my-2 p-2 text-red-300 w-full rounded-md border border-red-300 whitespace-break-spaces">
              {err}
            </p>
          )}
          {!nils(msg) && (
            <p className="my-2 p-2 text-acc0 w-full rounded-md border border-acc0 whitespace-break-spaces">
              {msg}
            </p>
          )}
          <div className="mx-auto w-max">
            <div className="fr-cc gap-2 my-2">
              <p>Selected Token</p>
              <p className={twMerge("resp-text-1")}>
                <span className={twMerge(toktextcn, "font-digi")}>{token}</span>
              </p>
            </div>
            <div className="fr-cc gap-2">
              <p className="resp-text--1">{"Select Quantity"}</p>
              <InpText
                id="inp_qty"
                contprops={{ className: "w-max bg-dark" }}
                inpprops={{ className: "w-[4rem]" }}
                def_val={qty}
                setter={() => {
                  if (loading) return;
                  let v = document.getElementById("inp_qty").value;
                  v = parseInt(v);
                  try {
                    // if (v > base.available)
                    //   throw new Error("not enough boxes available");

                    if (nils(v)) throw new Error("enter valid quantity");
                    if (!_.inRange(v, 1, 25.001))
                      throw new Error(
                        "min:1 max:25 boxes can be bought at a time",
                      );
                    else {
                      set_qty(v);
                      set_err(null);
                    }
                  } catch (err) {
                    set_err(err.message);
                  }
                }}
              />
            </div>
            <hr className="my-2" />
            <table className={tablecn.table_cn}>
              <tbody>
                <tr className={tablecn.tr_cn}>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Quantity</p>
                  </td>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">{qty} qty.</p>
                  </td>
                </tr>
                <tr className={tablecn.tr_cn}>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">LootBox Cost</p>
                  </td>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {dec(boxprice, _tokdecn)} {token}
                    </p>
                  </td>
                </tr>
                <tr className={tablecn.tr_cn}>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Total</p>
                  </td>
                  <td className={tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {`${dec(parseFloat(boxprice) * qty, _tokdecn)} ${token}`}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="fr-cc my-2">
              {loading ? (
                <>
                  <Loader01c size="s" />
                  <Tag className="">
                    please confirm transaction on MetaMask...
                  </Tag>
                </>
              ) : (
                <Tag
                  onClick={clickpayment}
                  className="fr-cc gap-2 bg-acc0/40 text-white transform -skew-x-12"
                >
                  <Img className={"w-[2rem]"} img={metamaskimg} />
                  <span>Pay Now</span>
                </Tag>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </div>
  );
};

const BuyBox = ({ boxtype }) => {
  const { info } = useSXContext();
  const mmcon = useMetaMaskContext();

  const boxtheme = boxtypes[boxtype] ?? {};

  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-max">
          <div className="fc-cc w-full flex-wrap relative">
            <div
              style={{
                backgroundColor: `${boxtheme?.acc_color}`,
              }}
              className={twMerge(
                "md:w-[8rem] md:h-[8rem]  md:top-[2rem] md:left-[0.5rem]",
                "xs:w-[8rem] xs:h-[12rem]  xs:top-[1rem] xs:left-[0.1rem]",
                "absolute translate-x-[50%] translate-y-[50%] z-[0] blur-3xl",
              )}
            ></div>
            <div
              style={{
                background: `linear-gradient(${boxtheme.acc_color}40 0%, rgba(100, 100, 100, 0) 100%)`,
              }}
              className="mint-box-container xs:mt-[50px] md:mt-[133px] xs:lg:w-[8rem] lg:w-[20rem]"
            >
              <div
                className={twMerge(
                  "md:top-[-6.5rem] md:w-[16rem]",
                  "xs:top-[-2rem] xs:w-[6rem]",
                  "img-obey-cont rotate-[0deg] absolute",
                )}
              >
                <img src={boxtheme.img} />
              </div>
              <div className="xs:h-[5rem] md:h-[7rem]"></div>
              <p className="resp-text-1 font-digi">
                <span className="italic">{_.upperCase(boxtheme.series)}</span>
              </p>
              <p className="resp-text-1 font-mon font-thin">Mint Skin Box</p>

              <hr className="w-full border-white/40 my-2" />

              {nils(info) ? (
                <Loader01c size="s" />
              ) : (
                <>
                  {info.buylock == 1 ? (
                    <>
                      <p className="text-red-300 resp-text--1">
                        <FontAwesomeIcon icon={faLock} />
                        <span className="px-1">
                          Buying Boxes is locked currently
                        </span>
                      </p>
                    </>
                  ) : (
                    <div className="grid grid-cols-2 resp-gap-2 w-full">
                      <BuyBoxBtn {...{ boxtheme, token: "WETH", boxtype }} />
                      <BuyBoxBtn {...{ boxtheme, token: "DEZ", boxtype }} />
                    </div>
                  )}
                </>
              )}

              <div className="fr-cc">
                <div className="">
                  <span
                    style={{ color: boxtheme.acc_color }}
                    className={twMerge("resp-text-1 font-digi")}
                  ></span>
                </div>

                <div className=""></div>
              </div>

              {mmcon.auth == false &&
                (mmcon.loading ? (
                  <Tag className="bg-dark text-acc0 fr-cc gap-2 font-digi">
                    <Loader01c size="s" />
                    <span>loading...</span>
                  </Tag>
                ) : (
                  <Tag
                    onClick={() => {
                      mmcon.login();
                    }}
                    className=" mt-4 mb-2 px-4 py-2 bg-acc0/80 text-black fr-cc gap-2"
                  >
                    <Img
                      className={"xs:w-[0.9rem] md:w-[1.5rem]"}
                      img={metamaskimg}
                    />
                    {mmcon.loading ? (
                      <span>loading...</span>
                    ) : (
                      <span>Connect Wallet</span>
                    )}
                  </Tag>
                ))}

              {/* {mmcon.auth == true && (
                <BuyBoxBtn {...{  boxtheme, boxtype }} />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const OpenedNFT = ({ s }) => {
  const txrow = (
    <>
      {!s.minted ? (
        <>
          <Loader01c size="s" />
          <span className="text-acc0 resp-text-0">Awaiting Mint...</span>
        </>
      ) : (
        <>
          {!nils(s.mint_hash) && (
            <Link
              className="fr-sc gap-1"
              target="_blank"
              to={polymaintxnlink(s.mint_hash)}
            >
              <Img
                className={twMerge(
                  "xs:w-[1.2rem] xs:h-[1.2rem]",
                  "md:w-[2rem] md:h-[2rem]",
                )}
                img={polychainimg}
              />

              <span className="text-purple-300 resp-text-0">
                {s.mint_hash.slice(0, 5)}..
              </span>
            </Link>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <div className=" bg-dark rounded-md ">
        <div className="fr-cc gap-2 p-2 px-4 resp-text-0 xs:hidden md:flex">
          <span>TokenID: {s.skinid ?? "--"}</span>
          <div className="flex-1"></div>
          {txrow}
        </div>

        <div className="fc-ss gap-2 p-2 px-4 resp-text-0 xs:flex md:hidden">
          <span className="resp-my-2">TokenID: {s.skinid ?? "--"}</span>
          {txrow}
        </div>

        <div>
          <SkinCard
            {...{
              skin: s.skin,
              rarity: s.rarity,
              viewdets: true,
            }}
          />
        </div>
      </div>
    </>
  );
};
const TxRow = ({ tx }) => {
  const [nftspopup, set_nftspopup] = useState(false);

  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const td_amt = (
    <div className="fr-sc xs:w-[5rem] lg:w-[8rem] resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <div className="xs:w-[0.8rem] lg:w-[1.6rem]"></div>
      {tx.type == "open_lootbox" ? (
        <>
          <span className="text-slate-300">--</span>
        </>
      ) : tx.type == "mint_lootbox" ? (
        <p className="text-green-400">
          <span>
            {dec(
              getv(tx, "connects.totpaid"),
              tokdecn(getv(tx, "connects.token")),
            )}
          </span>
          <span>{getv(tx, "connects.token")}</span>
        </p>
      ) : (
        <></>
      )}
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polymaintxnlink(hash)} className="xs:w-[1.5rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");
  const td_inout = (
    <>
      {tx.type == "mint_lootbox" && (
        <Tag className="bg-purple-500/20 text-white">{qty} boxes Minted</Tag>
      )}
      {tx.type == "open_lootbox" && (
        <Tag className="bg-yellow-500/20 text-white">{qty} box Opened</Tag>
      )}
    </>
  );

  const ls = getv(tx, "connects_post.skin");

  const td_view = (
    <>
      {tx.type == "open_lootbox" && (
        <>
          <Tag
            onClick={() => {
              set_nftspopup(true);
            }}
            className="bg-acc0/40 transform -skew-x-12 text-white gap-1 fr-cc"
          >
            <span>View NFTs</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>

          <PopUp
            wrapcn={twMerge("top-[5rem] left-[50%]")}
            innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
            openstate={nftspopup}
            overlayclose={false}
          >
            <Card className={"xs:w-full md:w-[50rem] max-w-[95rem] relative"}>
              <div className="fr-cc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => set_nftspopup(false)}
                  className={twMerge(
                    "fc-cc rounded-full bg-red-500 ",
                    "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                    "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                  )}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Tag>
              </div>
              {_.values(getv(tx, "connects_post.skins")).map((s) => {
                return <OpenedNFT s={s} />;
              })}
            </Card>
          </PopUp>
        </>
      )}
    </>
  );

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc0"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};
const Transactions = () => {
  const { txns } = useSXContext();
  return (
    <Card className={"mx-auto bg-reg xs:w-full md:w-max  overflow-auto"}>
      {_.isEmpty(txns) ? (
        <p className="resp-text--1">No Transactions Yet</p>
      ) : (
        <table className={tablecn.table_cn}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

const TopBuyBoxesBanner = () => {
  const appcon = useAppContext();
  const { tok_to_usd_val } = appcon;

  const mmcon = useMetaMaskContext();
  const { mmaccount: vault, auth } = mmcon;

  const { info, selview, set_selview } = useSXContext();

  const [tokenview, set_tokenview] = useState(true);
  useEffect(() => {
    const fn = () => {
      set_tokenview((tokenview) => !tokenview);
    };
    fn();
    setInterval(fn, 2000);
  }, []);

  const TokenPrice = ({ token, boxtype }) => {
    let price = getv(info, `pricemap.${token}.${boxtype}`);
    let pusd = tok_to_usd_val(price, token);
    return (
      <div className="xs:w-[6rem] lg:w-[8rem]">
        {tokenview ? (
          <>
            <FontAwesomeIcon icon={faUsd} />
            <span className="font-digi text-green-400 px-1">
              {dec(pusd, 2)}
            </span>
          </>
        ) : (
          <>
            <span className="text-white font-digi">
              {dec(price, token == "WETH" ? 4 : 0)}
            </span>
            <span className="text-white font-digi">{token}</span>
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="w-full overflow-auto bg-transparent select-none">
        <div className="w-full grid xs:grid-cols-1 lg:grid-cols-2 ">
          <div className="w-max mx-auto fc-cc xs:min-h-[10vh] lg:min-h-[50vh] max-h-[800px]">
            <table className={twMerge(tablecn.table_cn, "")}>
              <thead>
                <tr className="thintdrow font-digi resp-text--1">
                  <th></th>
                  <th className="xs:w-max lg:min-w-[10rem]">Series</th>
                  <th className="">WETH Cost</th>
                  <th className="">DEZ Cost</th>
                </tr>
              </thead>
              <tbody>
                {_.values(boxtypes).map((e) => {
                  return (
                    <tr
                      onClick={() => {
                        set_selview(e.boxtype);
                      }}
                      className={twMerge(
                        "thintdrowp4",
                        "resp-text--1",
                        "hover:bg-acc0/20",
                      )}
                    >
                      <td>{e.boxtype}</td>
                      <td>
                        <span className="text-white font-digi">
                          {_.capitalize(e.series)}
                        </span>
                      </td>
                      {!nils(info) && (
                        <>
                          <td className="">
                            <TokenPrice
                              {...{ token: "WETH", boxtype: e.boxtype }}
                            />
                          </td>
                          <td className="">
                            <TokenPrice
                              {...{ token: "DEZ", boxtype: e.boxtype }}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="w-max mx-auto xs:min-h-[10vh] lg:min-h-[50vh] max-h-[800px] fc-cc">
            <div className="fr-cc resp-gap-2">
              <Tag
                onClick={() => {
                  set_selview(((selview - 2 + boxtypes_n) % boxtypes_n) + 1);
                }}
                className="resp-px-4 resp-py-4 hover:bg-acc0/20"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Tag>
              <BuyBox {...{ boxtype: selview }} />
              <Tag
                onClick={() => {
                  set_selview((selview % boxtypes_n) + 1);
                }}
                className="resp-px-4 resp-py-4 hover:bg-acc0/20"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClosedBox = ({ boxtype }) => {
  const boxtheme = boxtypes[boxtype];

  const { mmaccount: vault, auth } = useMetaMaskContext();

  const { qo_txns } = useSXContext();

  const [resp, set_resp] = useState({});
  const [count_loading, set_count_loading] = useState(true);
  const [loading, set_loading] = useState(false);
  const [closed_n, set_closed_n] = useState(0);

  const update_closed = async () => {
    set_count_loading(true);
    try {
      const sxcon = await SkinLootbox_xmax2023.get_contract();

      let n = await sxcon.accountOwns(vault, boxtype);
      n = Number(n);
      console.log(boxtype, n);
      set_count_loading(false);
      return set_closed_n(n);
    } catch (err) {
      console.log(err);
      set_count_loading(false);
    }
  };
  useEffect(() => {
    let interval = null;
    if (auth == true) {
      setTimeout(update_closed, 1 * 1e3);
      interval = setInterval(update_closed, 2 * 60 * 1e3);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [auth]);

  const openbox = async () => {
    try {
      set_loading(true);
      const sxcon = await SkinLootbox_xmax2023.get_contract();
      let resp = await sxcon.openLootbox(1, boxtype);
      let hash = resp.hash;
      // console.log(resp);
      await q_skinxmas_txnque_add({ hash, vault, boxtype }).queryFn();
      await cdelay(5000);
      set_loading(false);
      setTimeout(update_closed, 5 * 1e3);
      setTimeout(qo_txns.refetch, 40 * 1e3);
    } catch (err) {
      console.log(err);
      set_loading(false);
    }
  };

  return (
    <div className=" rounded-md bg-reg fr-cc w-max">
      <Card
        className={twMerge(
          "resp-p-4 pl-[2rem] overflow-hidden w-max text-center bg-dark/20 mb-0",
        )}
      >
        <p className="font-digi resp-text-0 resp-my-2">
          {_.upperCase(boxtheme.series)}
        </p>
        <div className="fr-cc">
          <div className="relative xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] mx-auto ">
            <div
              style={{
                background: boxtheme.acc_color,
              }}
              className={twMerge(
                "absolute animate-fade-in xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] z-[5] blur-3xl",
              )}
            ></div>
            <Img
              img={boxtheme.img}
              className={
                "absolute xs:w-[5rem] md:w-[16rem] xs:h-[5rem] md:h-[16rem] z-[10] translate-x-[-20%] translate-y-[-20%]"
              }
            />
          </div>
          <div className="p-4">
            {!auth ? (
              <></>
            ) : count_loading ? (
              <div className="fr-sc my-1">
                <div className="spin-anim">
                  <FontAwesomeIcon className="resp-text-1" icon={faRefresh} />
                </div>
              </div>
            ) : (
              <p className="text-acc0 font-digi resp-text-2">x {closed_n}</p>
            )}
          </div>
        </div>
        {closed_n > 0 ? (
          <div className="fr-sc my-1">
            <div className="flex-1"></div>
            {loading && <Loader01c size="s" />}
            <Tag
              onClick={() => {
                if (loading) return;
                openbox();
              }}
              style={{ backgroundColor: `${boxtheme.acc_color}` }}
              className="-skew-x-12 text-white font-digi"
            >
              Open 1 Box
            </Tag>
          </div>
        ) : (
          <div className="fr-sc my-1">
            <Tag className="opacity-5">-</Tag>
          </div>
        )}
        {!nils(resp.err) && (
          <div className="fr-sc my-4">
            <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
          </div>
        )}
      </Card>
    </div>
  );
};
const OwnedBoxesList = () => {
  return (
    <div className="fr-cc gap-2 flex-wrap">
      {_.values(boxtypes).map((e) => {
        return <ClosedBox {...{ boxtype: e.boxtype }} />;
      })}
    </div>
  );
};

const TeamCard = ({ cardcn, textcn, title, skins = [] }) => {
  return (
    <Card className={twMerge(cardcn, "w-full")}>
      <p
        className={twMerge(textcn, "italic text-center resp-text-1 font-digi")}
      >
        {title}
      </p>

      <div className="grid xs:grid-cols-1 lg:grid-cols-1 my-2 w-full resp-gap-2">
        {skins.map((s) => {
          return (
            <div className="col-span-1 resp-text--3 fc-cc w-full">
              <p className="text-center font-digi text-white resp-text--1 my-2">
                {s[0]}
              </p>
              <div className="xs:max-w-full lg:w-[20rem]">
                <SkinCard
                  {...{
                    skin: s[1],
                    rarity: "exclusive",
                    viewdets: true,
                    namecn:
                      "xs:text-[0.4rem] md:text-[0.6rem] lg:text-[0.8rem] 2xl:text-[0.8rem]",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

const BannerWrapper = ({ children }) => {
  return (
    <div className="xs:hidden md:block max-h-[calc(800px)] h-[40vh] ">
      <div className="">
        <div
          style={{ backgroundImage: `url(${xmasbannerimg})` }}
          className="hero-section"
        >
          <div className="html-overlay relative">
            <div className="">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SkinsTopBanner = () => {
  return (
    <div className="w-full">
      <div className="grid grid-cols-2 resp-gap-2">
        <TeamCard
          cardcn={"bg-red-500/20"}
          textcn={"text-red-300"}
          title={"Naughty"}
          skins={[
            ["Grinder", "Bad Santa"],
            ["Qualified", "Frostbite"],
          ]}
        />

        <TeamCard
          cardcn={"bg-blue-500/20"}
          textcn={"text-blue-300"}
          title={"Nice"}
          skins={[
            ["Grinder", "Good Santa"],
            ["Qualified", "North Star"],
          ]}
        />
      </div>
    </div>
  );
};

const EventsInformation = () => {
  const trs = [
    ["", "10 races minimum", "10 races minimum", "10 races minimum"],
    ["", "Double Up", "Variance", "spins"],
    [
      "",
      "must qualify top 5 naughty top5 nice",
      "must qualify top 5 naughty top5 nice",
      "must qualify top 5 naughty top5 nice",
    ],
    [
      "race entry fee",
      "3-5 random who completed 10 races",
      "3-5 random who completed 10 races",
      "3-5 random who completed 10 races",
    ],
    [
      <span className="text-acc0 font-digi">${`${dec(1.5, 2)}`}</span>,
      "metal",
      "metal",
      "metal",
    ],
    [
      <span className="text-acc0 font-digi">${`${dec(1.25, 2)}`}</span>,
      "fire",
      "fire",
      "fire",
    ],
    [
      <span className="text-acc0 font-digi">${`${dec(1.0, 2)}`}</span>,
      "earth",
      "earth",
      "earth",
    ],
    [
      <span className="text-acc0 font-digi">${`${dec(0.75, 2)}`}</span>,
      "water",
      "water",
      "water",
    ],
    [
      <span className="text-acc0 font-digi">${`${dec(0.5, 2)}`}</span>,
      "bred",
      "bred",
      "bred",
    ],
    ["", "", "", ""],
    ["Finals", "qualifiers", "qualifiers", "qualifiers"],
    ["", "sit and go's", "sit and go's", "sit and go's"],
    ["", "all distances", "all distances", "all distances"],
    ["", "double up tourney", "variance tourney", "wta"],
    [
      "",
      "T3 most points 10% of sold",
      "T3 most points 10% of sold",
      "most points 10% of sold",
    ],
  ];

  return (
    <Card className={"bg-black/10 resp-text--1"}>
      <p className="my-2 text-center text-acc0 font-digi resp-text-2">
        {"Santa Qualifiers"}
      </p>
      <table className={twMerge(tablecn.table, "w-full resp-text--2")}>
        <tbody>
          {trs.map((e) => {
            return (
              <tr className={twMerge("thintdrowp4")}>
                {e.map((e) => {
                  return (
                    <td>
                      <p className="text-white resp-text--2 whitespace-break-spaces">
                        {e}
                      </p>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <p className="my-1 resp-text--2 resp-p-1">
        most points of all 3 Finals per team 70% of prize pool
      </p>
      <p className="my-1 resp-text--2 resp-p-1">
        if sit and go has odd number we only count till we run out of evens to
        keep team scoring competitive
      </p>
    </Card>
  );
};

export const Skin_Xmas_Page = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const mmcon = useMetaMaskContext();
  const { vault, auth } = mmcon;

  const [tab, set_tab] = useState(psearch.tab ?? "minting");

  const [qo_info, qo_txns] = useQueries([
    q_skinxmas_info({}),
    q_skinxmas_txns({ vault }, { enabled: !nils(vault) && tab == "minting" }),
  ]);
  const txns = useMemo(() => {
    if (qissuccesss(qo_txns)) return getv(qo_txns, "data.result");
    return null;
  }, [qo_txns.dataUpdatedAt]);

  const info = useMemo(
    () => getv(qo_info, "data.result"),
    [qo_info.dataUpdatedAt],
  );

  const [selview, set_selview] = useState(psearch.selview ?? 1);
  useEffect(() => {
    const rem = { selview, tab };
    upd_psearch(rem);
  }, [selview, tab]);

  const sxcon = {
    auth,
    vault,

    info,
    txns,

    qo_info,
    qo_txns,

    selview,
    set_selview,

    tab,
    set_tab,
  };
  return (
    <SXContext.Provider value={sxcon}>
      <Helmet>
        <title>Skin X-Mas 2023 || DNA Racing</title>
      </Helmet>
      <div className="h-page">
        <div className="my-[1rem] fr-cc resp-gap-2">
          {[
            ["minting", "Minting"],
            ["skins", "Skins"],
            ["event", "Event"],
          ].map((e) => {
            return (
              <Tag
                key={e[0]}
                onClick={() => {
                  set_tab(e[0]);
                }}
                className={twMerge(
                  "resp-text-1 font-digi transition duration-200",
                  tab == e[0] ? "bg-acc0/40 -skew-x-12" : "text-white",
                )}
              >
                {e[1]}
              </Tag>
            );
          })}
        </div>

        <div className="h-[1rem]"></div>
        <>
          {tab == "event" ? (
            <>
              <div className="max-w-[98vw] w-[120rem] mx-auto">
                <EventsInformation />
              </div>
            </>
          ) : tab == "skins" ? (
            <>
              <div className="max-w-[98vw] w-[120rem] mx-auto">
                <div className="xs:hidden md:block max-h-[800px] h-[calc(100vh-9rem)] ">
                  <BannerWrapper>
                    <SkinsTopBanner />
                  </BannerWrapper>
                </div>
                <div className="xs:block md:hidden">
                  <SkinsTopBanner />
                </div>
              </div>
            </>
          ) : tab == "minting" ? (
            <>
              <div className="relative">
                <div className="xs:hidden md:block max-h-[800px] h-[calc(100vh-9rem)] ">
                  <BannerWrapper>
                    <TopBuyBoxesBanner />
                  </BannerWrapper>
                </div>
                <div className="xs:block md:hidden">
                  <div className="home-container">
                    <TopBuyBoxesBanner />
                  </div>
                </div>
              </div>

              <div className="max-w-[98vw] w-[70rem] mx-auto">
                <div className="h-[2rem]"></div>
                {mmcon.auth && (
                  <>
                    <p className="text-center text-acc0 resp-text-1">
                      Closed Boxes
                    </p>
                    <OwnedBoxesList />
                  </>
                )}

                <div className="h-[2rem]"></div>
                <div className="w-full">
                  {auth && !nils(vault) ? (
                    <>
                      {qo_txns.isLoading ? (
                        <Loader01c size="xs" />
                      ) : qiserr(qo_txns) ? (
                        <p className="text-red-300">{qiserr(qo_txns)}</p>
                      ) : qissuccesss(qo_txns) ? (
                        <>
                          <p className="text-center resp-text-1 font-digi text-acc0">
                            Transactions
                          </p>
                          <Transactions />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      <Card>
                        <div className="text-center text-acc0 font-digi resp-text--1 fr-cc resp-gap-1">
                          <Tag
                            className="fr-sc resp-gap-1"
                            onClick={() => mmcon.login()}
                          >
                            <div className="xs:w-[1rem] md:w-[2rem]">
                              <Img img={metamaskimg} />
                            </div>
                            <span className="text-orange-400 resp-text--1 underline underline-orange-400">
                              Login
                            </span>
                          </Tag>
                          <span>To see Txns</span>
                        </div>
                      </Card>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </>
      </div>
    </SXContext.Provider>
  );
};
