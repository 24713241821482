import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { Card, Dropdown, Tag } from "./utilityComps.js";
import { useAppContext } from "../App.js";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBank,
  faBarcode,
  faBars,
  faClose,
  faGear,
  faPerson,
  faSignOut,
  faTrash,
  faUser,
  faVault,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { motion } from "framer-motion";
import { getv, jstr, nils, trim_addr, trim_n } from "../utils/utils.js";
import { Mo, MoVariants } from "../utils/motion_helper.js";
import { useRef } from "react";
import { useAccountContext } from "../wrappers/AccountWrapper.js";
import Login from "../views/Login.js";
import {
  ThridWebLoginSection,
  useThirdWebLoginContext,
} from "../views/ThirdWebLogin.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";

const LayoutContext = React.createContext({});
export const useLayoutContext = () => React.useContext(LayoutContext);

export const logoimg = "https://cdn.dnaracing.run/imgs/dna_logo.png";
export const Logo = ({ title = "", title2 = "" }) => {
  return (
    <div className="fr-cc">
      <div className="xs:w-[8rem] lg:w-[15rem] aspect-[1879/504]  xs:ml-1 lg:ml-4 resp-p-2">
        <img className="h-full object-contain" src={logoimg} alt="" />
      </div>
    </div>
  );
};

const clearSiteData = async () => {
  // Clear localStorage
  localStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear IndexedDB (example for a database named 'myDatabase')
  const req = indexedDB.deleteDatabase("gltfCache");
  req.onsuccess = function () {
    console.log("Deleted database successfully");
  };
  req.onerror = function () {
    console.log("Couldn't delete database");
  };
  req.onblocked = function () {
    console.log("Couldn't delete database due to the operation being blocked");
  };

  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

const NavLogo = () => {
  return (
    <div class="layout-navlogo">
      <Link to={"/"}>
        <Logo />
      </Link>
    </div>
  );
};

const linkcn = "hover:text-acc4 font-mon resp-px-2 resp-text-0 fr-sc h-full";
const smlinkcn = "hover:text-acc4 font-digi resp-px-2 resp-text-2";

const DropLink = ({ txt, links }) => {
  const dropcn = `droplink-${txt}`;
  const dropref = useRef(null);

  const [hover, set_hover] = useState(false);
  const history = useNavigate();

  const linkcn = "hover:text-acc4 font-mon resp-px-2 resp-text-0 fr-sc h-full";
  return (
    <div
      ref={dropref}
      onMouseLeave={() => {
        set_hover(false);
      }}
      className={twMerge(dropcn, "w-max h-full cursor-pointer relative")}
    >
      <div
        onMouseEnter={() => {
          set_hover(true);
        }}
        onClick={() => {
          let url = links[0][0];
          history(url);
        }}
        className={twMerge("main-link", linkcn, "fr-sc resp-gap-1")}
      >
        <span className="resp-text-0 font-reg">{txt}</span>
        <FontAwesomeIcon
          className={twMerge(
            "text-slate-600 resp-text--1 transition duration-300",
            hover ? "rotate-180" : "",
          )}
          icon={faAngleDown}
        />
      </div>
      <motion.div
        variants={MoVariants.show_hide}
        animate={hover ? "visible" : "hidden"}
        className="absolute min-w-[15rem] w-[15rem] bg-r2dark/80 backdrop-blur-md top-[3rem] left-0 rounded-md z-30"
      >
        <div className="card-grad-border rounded-md p-[0.2rem]">
          <div className="resp-p-4 ">
            {links.map(([to, txt, cn, fn, ico]) => {
              return (
                <div className="w-full  resp-py-2">
                  <Link
                    onClick={() => {
                      if (fn) fn();
                      set_hover(false);
                    }}
                    to={to}
                    className={twMerge(cn, "fr-sc resp-gap-2 hover:text-acc4")}
                  >
                    <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                    <span>{txt}</span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
};
const SM_DropLink = ({ txt, links, set_smshow }) => {
  const dropcn = `smdroplink-${txt}`;
  const [show, set_show] = useState(false);

  return (
    <div className={twMerge(dropcn, "w-max h-full cursor-pointer relative")}>
      <div
        onClick={() => {
          set_show(!show);
        }}
        className={twMerge(smlinkcn, "fr-cc resp-gap-1")}
      >
        <span>{txt}</span>
        <FontAwesomeIcon
          className={twMerge(smlinkcn, show ? "rotate-180" : "")}
          icon={faAngleDown}
        />
      </div>
      <motion.div
        variants={MoVariants.show_hide}
        className=""
        animate={show ? "visible" : "hidden"}
      >
        <div class="fc-cc resp-p-1 resp-gap-1 rounded-lg card-basic-bg">
          {links.map(([to, txt, cn, fn, ico], li) => {
            return (
              <div className="w-full  resp-py-2">
                <Link
                  onClick={() => {
                    set_smshow(false);
                  }}
                  to={to}
                  className={twMerge(
                    `link-${li}`,
                    cn,
                    smlinkcn,
                    "fr-sc resp-gap-2",
                  )}
                >
                  <FontAwesomeIcon className="w-[1rem]" icon={ico} />
                  <span>{txt}</span>
                </Link>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export const SMCloseBtn = ({ closefn = () => {}, extcn = "" }) => {
  return (
    <div
      className={twMerge(extcn, "w-full fixed bottom-[3rem] z-[10]", "fr-cc ")}
      onClick={closefn}
    >
      <Tag className="fr-sc resp-gap-1 bg-r2lig xs:px-[1rem] xs:py-[0.5rem] rounded-full">
        <FontAwesomeIcon className="resp-text-3" icon={faClose} />
        <span className="resp-text-1">Close</span>
      </Tag>
    </div>
  );
};
const SM_MenuLayout = ({ trigger, show, set_show, ...props }) => {
  // const [show, set_show] = useState(true);

  return (
    <>
      <div
        onClick={() => set_show(!show)}
        className={twMerge(twMerge("sm-nav-menu-trigger", "cursor-pointer"))}
      >
        {trigger}
      </div>
      <motion.div
        {...Mo.slide({ x: ["-100%", 0, "100%"] })}
        animate={show ? "visible" : "hidden"}
        transition={{ delay: show ? 0.1 : 0.25 }}
        className="z-[50] h-page fixed top-[4.4rem] left-0 w-full bg-r2dark"
      >
        <div class="h-[75vh] overflow-auto">{props.children}</div>
        <SMCloseBtn closefn={() => set_show(false)} extcn="smclose-nav" />
      </motion.div>
    </>
  );
};

const ShowVaultIcon = () => {
  const mmcon = useMetaMaskContext();
  const tcon = useThirdWebLoginContext();
  const aucon = useAuthContext();

  const { auth } = aucon;
  const { vault } = mmcon;
  const { vdoc } = useAccountContext();

  const txt = useMemo(() => {
    if (!nils(vdoc?.vault_name)) return trim_n(vdoc.vault_name, 15);
    if (!nils(vault)) return trim_addr(vault);
    return "--";
  }, [jstr(vdoc), vault]);
  const laycon = useLayoutContext();
  const { set_loginpop } = laycon;

  const whole_logout = async () => {
    await tcon.logout();
    await aucon.logout();
  };

  if (!auth) return null;
  return (
    <div
      onClick={() => {
        whole_logout();
      }}
      className="mx-2 cursor-pointer my-auto rounded-full bg-acc4/20 text-acc4 font-mon px-4 py-1  fc-cc"
    >
      <span class="resp-text--1">{txt}</span>
    </div>
  );
};

const Nav = () => {
  const { path, history } = useAppContext();
  const mmcon = useMetaMaskContext();
  const { auth, auth_refresh, aumode } = mmcon;
  const { set_loginpop } = useLayoutContext();

  const linkcn = "hover:text-acc4 font-mon px-2 resp-text--2 fc-cc h-full";
  const smlinkcn = "hover:text-acc4 font-mon px-2 resp-text--2 fc-cc h-full";
  const activecn = "border-b border-acc4 text-acc4";

  const droplinks = [
    [
      "Assets",
      [
        [`/seasonpass`, "Season Pass"],
        [`/mint_v2`, "Mint V2"],
        [`/bikeburn`, "Bike Burn"],
        [`/factions`, "Factions"],
      ],
    ],
    [
      "Skins",
      [
        [`/skins`, "Skins"],
        [`/skinmint`, "Mint Skins"],
        [`/skingame`, "Skin Game"],
      ],
    ],
    [
      "More Info",
      [
        [`/moreinfo`, "More Info"],
        [`/gameloop`, "Game Loop"],
        [`/tokenomics`, "Tokenomics"],
        [`/funds`, "Funds"],
      ],
    ],
  ];

  const links = [
    [`/prizebox`, "Prize Box"],
    [`/tp_giftcard`, "TP GiftCards"],
    [`/tutorial`, "Tutorial"],
    [`https://fbike.dnaracing.run/races`, "Lobby"],
    [`https://market.dnaracing.run/`, "Market"],
  ];

  const options = [
    [
      null,
      "clear cache",
      twMerge(linkcn, "text-orange-300"),
      clearSiteData,
      faTrash,
    ],
  ];

  const [smshow, set_smshow] = useState(false);

  const show_t3login = !(aumode == "wallet" && auth == true);
  const login_vault_jsx = (
    <>
      {show_t3login && (
        <ThridWebLoginSection
          btntext="T3 Login"
          postlogin={async () => {
            if (auth_refresh) await auth_refresh();
          }}
        />
      )}
      {nils(mmcon.vault) ? (
        <div
          onClick={() => {
            set_loginpop(true);
          }}
          className=" cursor-pointer  mx-2 rounded-full bg-acc1 font-mon px-4 py-2"
        >
          Login
        </div>
      ) : (
        <ShowVaultIcon />
      )}
    </>
  );

  return (
    <>
      <div class="large-screen xs:hidden lg:block">
        <div class="bg-transparent h-[4.4rem] fr-sc">
          <NavLogo />
          <div className="flex-1"></div>
          <>
            <div class="w-[6rem]"></div>
            {droplinks.map(([txt, links]) => {
              return <DropLink {...{ key: txt, txt, links }} />;
            })}
            {links.map(([to, txt]) => {
              let target = to && to.startsWith("/") ? "_self" : "_blank";
              return (
                <Link
                  key={to}
                  to={to}
                  target={target}
                  className={twMerge(linkcn, path == to ? activecn : "")}
                >
                  <span className="resp-text-0">{txt}</span>
                </Link>
              );
            })}
          </>
          <div className="flex-1"></div>
          {login_vault_jsx}
        </div>
      </div>
      <div class="small-screen xs:block lg:hidden">
        <div class="bg-transparent h-[4.4rem] fr-sc resp-gap-1 resp-px-2">
          <SM_MenuLayout
            show={smshow}
            set_show={set_smshow}
            trigger={
              <div class="resp-p-2">
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-white resp-text-3"
                />
              </div>
            }
          >
            <div class="fc-cc resp-gap-2 resp-text-2">
              <div class="h-[4rem]"></div>
              <>
                {droplinks.map(([txt, links]) => {
                  return (
                    <SM_DropLink {...{ key: txt, txt, links, set_smshow }} />
                  );
                })}
                {links.map(([to, txt]) => {
                  let target = to && to.startsWith("/") ? "_self" : "_blank";
                  return (
                    <Link
                      onClick={() => {
                        set_smshow(false);
                      }}
                      key={to}
                      to={to}
                      target={target}
                      className={twMerge(smlinkcn, path == to ? activecn : "")}
                    >
                      <span className="resp-text-2 font-digi">{txt}</span>
                    </Link>
                  );
                })}
                {options.slice(0, -2).map(([to, txt, cn, fn, ico]) => {
                  return (
                    <Link
                      onClick={() => {
                        set_smshow(false);
                      }}
                      to={to}
                      className={twMerge(smlinkcn)}
                    >
                      <span className="resp-text-2 font-digi">{txt}</span>
                    </Link>
                  );
                })}
              </>
            </div>
            <div className="h-[5rem]"> </div>
          </SM_MenuLayout>
          <NavLogo />
          <div class="flex-1"></div>

          {login_vault_jsx}
        </div>
      </div>
    </>
  );
};

function Layout(props) {
  const [loginpop, set_loginpop] = useState(false);
  // const open_loginpop = () => set_loginpop(true);
  const open_loginpop = () => {
    set_loginpop(true);
  };
  const laycon = { loginpop, set_loginpop, open_loginpop };
  return (
    <LayoutContext.Provider value={laycon}>
      <div className="relative">
        <Nav />
        {loginpop && (
          <div className="absolute z-[3000] top-[15vh] left-[50%] transform translate-x-[-50%] h-0">
            <Login onclose={() => set_loginpop(false)} />
          </div>
        )}
        <main
          className={
            twMerge("")
            // "md:max-w-[80rem] md:w-full mx-auto",
            // "xs:max-w-[95vw] mx-auto"
          }
        >
          {props.children}
        </main>
      </div>
    </LayoutContext.Provider>
  );
}

export default Layout;
