import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppContext, useNowContext } from "../App.js";
import {
  token_locpath,
  tokenacc_locpath,
  useMetaMaskContext,
  page_bypass_fn,
  tokenaumode_locpath,
  tokenauth_locpath,
} from "./MetaMaskWrapper.js";
import {
  q_auth_verify_token,
  q_vaultbikes,
  q_vaultinfo,
  qiserr,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { cdelay, getv, jstr, nils } from "../utils/utils.js";
import Layout from "../components/Layout.js";
import { backdropimg } from "../utils/links.js";
import { useThirdWebLoginContext } from "../views/ThirdWebLogin.js";
import _ from "lodash";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const get_auth_header = () => {
  let token = localStorage.getItem(token_locpath);
  let vault = localStorage.getItem(tokenacc_locpath);
  let aumode = localStorage.getItem(tokenaumode_locpath);
  if (vault) vault = vault.toLowerCase();
  return {
    "x-token": nils(token) ? null : token,
    "x-vault": nils(vault) ? null : vault,
    "x-aumode": nils(aumode) ? null : aumode,
  };
};

export const NoAuthBackdrop = (props) => {
  return (
    <div
      style={{
        height: `92vh`,
        background: `url(${backdropimg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backdropFilter: "blur(10px)",
      }}
      className="w-[100vw] overflow-hidden"
    >
      {props.children}
    </div>
  );
};

function AuthWrapper(props) {
  const { now } = useNowContext();

  const { path, history } = useAppContext();
  const mmcon = useMetaMaskContext();
  const t3con = useThirdWebLoginContext();

  const bypass = useMemo(() => {
    if (page_bypass_fn(path)) return true;
    return false;
  }, [path]);

  useEffect(() => {
    if (!window.ethereum) return;
    if (!mmcon.mm_connected) return;

    window.ethereum.on("accountsChanged", () => {
      console.log("accountsChanged");
      set_refreshc(refreshc + 1);
    });
  }, [mmcon.mm_connected]);

  const [refreshc, set_refreshc] = useState(0);
  // useEffect(() => { console.log("refreshc", refreshc); }, [refreshc]);
  const grab_loc_cache = () => {
    let aumode = localStorage.getItem(tokenaumode_locpath);

    if (aumode == "wallet") {
      if (!window.ethereum) return [null, null, null];
      const fn = () => {
        let account = window.ethereum.selectedAddress;
        if (!nils(account)) account = account.toLowerCase();
        let token = mmcon.get_token();
        // console.log("refreshc", refreshc, account, token);
        return [aumode, account, token];
      };
      return fn();
    } else if (aumode == "thirdweb") {
      if (!t3con.connected) return [null, null, null];
      let account = localStorage.getItem(tokenacc_locpath);
      let token = localStorage.getItem(token_locpath);
      // console.log("thirdweb", { account, token, con: t3con.connected });
      if (!token || !account) {
        localStorage.removeItem(tokenauth_locpath);
        return [aumode, null, null];
      }

      return [aumode, account, token];
    } else return [null, null, null];
  };
  const [aumode, account, token] = useMemo(() => {
    let cac_resp = grab_loc_cache();
    console.log("use_memo:auth:cac_resp", { refreshc }, cac_resp[0]);
    return cac_resp;
  }, [refreshc, t3con.connected]);

  useEffect(() => {
    if (!account && bypass == true) {
      setTimeout(() => {
        set_refreshc(refreshc + 1);
      }, 1000);
    }
  }, [bypass]);

  const vault = account && account.toLowerCase();

  const qo_enabled = !nils(vault) && !nils(token) && !nils(aumode);
  const [qo_auth] = useQueries([
    {
      queryKey: ["auth", jstr({ vault, token, aumode })],
      queryFn: () => {
        return q_auth_verify_token({ vault, token });
      },
      staleTime: 5 * 60 * 1e3,
      enabled: qo_enabled,
    },
  ]);

  const auth_refresh = async () => {
    // console.log("auth_refresh");
    set_refreshc(refreshc + 1);
    await cdelay(500);
    await qo_auth.refetch();
  };
  useEffect(() => {
    window.auth_refresh = auth_refresh;
  }, [auth_refresh]);

  const logout = async () => {
    localStorage.removeItem(token_locpath);
    localStorage.removeItem(tokenacc_locpath);
    localStorage.removeItem(tokenaumode_locpath);
    localStorage.removeItem(tokenauth_locpath);
    await auth_refresh();
  };

  const [auth, autherr] = useMemo(() => {
    // console.log("qo_auth", qo_enabled, qo_auth);
    if (!qo_enabled) return [false, "couldnt find auth details"];

    let err = qiserr(qo_auth);

    if (err) {
      // history("/");
      localStorage.removeItem(token_locpath);
      localStorage.removeItem(tokenacc_locpath);
      return [false, err];
    }
    let auth = getv(qo_auth, "data.result.auth");
    let isempty = _.isEmpty(qo_auth?.data);
    if (isempty) return [false, "---"];
    console.log("auth", auth);

    if (auth) {
      localStorage.setItem(tokenauth_locpath, "true");
      return [true, null];
    } else {
      localStorage.setItem(tokenauth_locpath, "false");
      return [false, `couldn't authenticate`];
    }
  }, [qo_auth.dataUpdatedAt, aumode, t3con.connected]);

  useEffect(() => {
    if (qo_auth.isLoading) return;
    if (auth == false && bypass == false) {
      history("/");
    }
  }, [auth, autherr, qo_auth.dataUpdatedAt]);

  useEffect(() => {
    window.auth_logout = logout;
  }, [logout]);

  const [init_lock, set_init_lock] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      set_init_lock(false);
    }, 10 * 1e3);
  }, []);

  useEffect(() => {
    // console.log({ init_lock });
    if (init_lock) return;
    if (aumode !== "thirdweb") return;
    // console.log("manager", { auth, aumode, t3con: t3con.connected, init_lock });
  }, [auth, aumode, t3con.connected, init_lock]);

  const [qo_vaultinfo, qo_vaultbikes] = useQueries([
    q_vaultinfo({ vault }, { enabled: auth == true }),
    q_vaultbikes({ vault }, { enabled: auth == true }),
  ]);
  const vaultdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);
  const vaultbikes = useMemo(() => {
    let vaultbikes = getv(qo_vaultbikes, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultbikes.dataUpdatedAt]);

  const sel_correct_chain = async () => {
    try {
      const corr_chainId = 137;
      const corr_chainId_hex = "0x" + corr_chainId.toString(16);

      const mm = window.ethereum;
      if (nils(mm)) return;
      let chainIdHex = await mm.request({ method: "eth_chainId" });
      const chainId = parseInt(chainIdHex, 16);
      if (chainId !== corr_chainId) {
        await mm.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: corr_chainId_hex }],
        });
      }
    } catch (err) {}
  };
  useEffect(() => {
    if (auth == true) {
      sel_correct_chain();
    }
  }, [auth]);
  // console.log("auth", { auth, vault });

  const aucon = {
    auth,
    account,
    vault,
    token,
    loading: qo_auth.isLoading,
    auth_refresh,
    logout,

    qo_auth,

    qo_vaultinfo,
    vaultdoc,

    qo_vaultbikes,
    vaultbikes,

    aumode,
  };

  return (
    <AuthContext.Provider value={aucon}>
      {bypass == true ? (
        props.children
      ) : (
        <>
          {auth == true ? (
            props.children
          ) : (
            <>
              <Layout>
                <NoAuthBackdrop />
              </Layout>
            </>
          )}
        </>
      )}
    </AuthContext.Provider>
  );
}

export default AuthWrapper;
