import {
  faChevronRight,
  faClose,
  faQuestion,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Array2D_Tablecn,
  BikeCard,
  Card,
  Img,
  InpText,
  MiniStatCard,
  PopupCloseBtn,
  Tag,
  TokenIcon,
} from "../components/utilityComps";
import { metamaskimg, polychainimg, polyimg, wethimg } from "../utils/links";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { Loader01c } from "../components/anims.js";
import { PopUp } from "../components/popup";
import { twMerge } from "tailwind-merge";
import { useQueries } from "react-query";
import {
  qiserr,
  qissuccesss,
  polytxnlink,
  q_corelootbox_v2_lootnfts,
  polymaintxnlink,
  q_factions_lootbox_baseinfo,
  q_factions_lootbox_txns,
  q_que_txn,
  q_factions_lootbox_agentinfo_early,
} from "../queries/queries.js";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  nano,
  nils,
  tofeth,
  to_time_mini,
} from "../utils/utils.js";
import { transfer_tokens } from "../queries/chainreq.js";
import _ from "lodash";
import { Input } from "../components/input.js";
import WETH_MockToken from "../contracts/WETH_MockToken/WETH_MockTokenContract.js";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { tokdecn } from "../App.js";
import FactionsAgentsBox from "../contracts/FactionsAgentsBox/FactionsAgentsBox.js";
import { mm_asset_signer } from "../contracts/contract_funcs";
import { useAuthContext } from "../wrappers/AuthWrapper";
import { useLayoutContext } from "../components/Layout";

const countdown = "2024-05-15T00:00:00Z";

const MintContext = createContext({});
export const useMintContext = () => useContext(MintContext);

const Buy = ({ base, token }) => {
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const { qotxns, upd_closed_n } = useMintContext();

  const on_buyclick = async () => {};

  const [popup, set_popup] = useState(false);
  const [qty, set_qty] = useState(1);
  const [err, set_err] = useState(null);
  const [msg, set_msg] = useState(null);

  // useEffect(() => {
  //   try {
  //     let v = qty;
  //     if (v > base.available) throw new Error("not enough boxes available");
  //     v = parseInt(v);
  //     if (nils(v)) throw new Error("enter valid quantity");
  //     if (!_.inRange(v, 1, 25.001))
  //       throw new Error("min:1 max:25 boxes can be bought at a time");
  //     else set_err(null);
  //   } catch (err) {
  //     set_err(err.message);
  //   }
  // }, [qty]);

  const [loading, set_loading] = useState(false);
  const clickpayment = async () => {
    try {
      set_loading(true);
      if (nils(token)) throw new Error("token not recognized");

      let price = getv(base, `tokmap.${token}.price`);
      const topay = price * qty;

      const lootcon = await FactionsAgentsBox.get_contract();
      const paycon = await mm_asset_signer(
        token == "DEZ" ? "dez" : token == "WETH" ? "weth" : null,
      );

      let balance = await paycon.balanceOf(vault);
      balance = parseFloat(tofeth(balance));
      if (balance < topay) throw new Error("not enough balance");

      try {
        let allowance = await paycon.allowance(vault, lootcon.contractAddress);
        allowance = parseFloat(tofeth(allowance));
        if (allowance < topay) {
          const approval = await paycon.approve(
            lootcon.contractAddress,
            ethers.parseEther(topay.toString()),
          );
          await approval.wait();
          await cdelay(1000);
        }

        let resp = await lootcon.buyLootbox(qty, token);
        resp = await resp.wait();
        await q_que_txn({
          service: "factionsagentsbox",
          hash: resp.hash,
        }).queryFn();

        console.log(resp);
      } catch (err) {
        throw new Error(`Metamask: ${err.reason}`);
      }

      set_err(null);

      await cdelay(5000);

      setTimeout(() => {
        upd_closed_n();
      }, 5 * 1e3);

      setTimeout(() => {
        qotxns.refetch();
      }, 8 * 1e3);

      set_loading(false);
      set_popup(false);
    } catch (err) {
      set_err(err.message);
      set_loading(false);
    }
  };

  return (
    <>
      <Tag
        onClick={() => {
          set_popup(true);
        }}
        className={twMerge(
          token == "WETH"
            ? "bg-purple-500/50"
            : token == "DEZ"
              ? "bg-acc4/40"
              : "",
        )}
      >
        <div class={twMerge("fr-cc resp-gap-1")}>
          <span>Buy</span>
          <TokenIcon token={token} />
          <span>{token}</span>
        </div>
      </Tag>

      <PopUp openstate={popup} overlayclose={false}>
        <Card className={"bg-dark min-w-[30rem]"}>
          <div className="fr-cc">
            <div class="flex-1"></div>
            <PopupCloseBtn closepopup={() => set_popup(false)} />
          </div>
          {!nils(err) && (
            <p className="my-2 p-2 text-red-300 w-full rounded-md border border-red-300">
              {err}
            </p>
          )}
          {!nils(msg) && (
            <p className="my-2 p-2 text-acc4 w-full rounded-md border border-acc4">
              {msg}
            </p>
          )}
          <div className="mx-auto w-max">
            <div className="fr-cc gap-2">
              <p>{"Select Quantity"}</p>
              <InpText
                id="inp_qty"
                contprops={{ className: "w-max bg-dark" }}
                inpprops={{ className: "w-[4rem]" }}
                def_val={qty}
                setter={() => {
                  if (loading) return;
                  let v = document.getElementById("inp_qty").value;
                  v = parseInt(v);
                  try {
                    if (v > base.available)
                      throw new Error("not enough boxes available");

                    if (nils(v)) throw new Error("enter valid quantity");
                    if (!_.inRange(v, 1, 1.001))
                      throw new Error("only 1 box can be bought at a time");
                    else {
                      set_qty(v);
                      set_err(null);
                    }
                  } catch (err) {
                    set_err(err.message);
                  }
                }}
              />
            </div>
            <hr className="my-2" />
            <table className={Array2D_Tablecn.table_cn}>
              <tbody>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Quantity</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">{qty} qty.</p>
                  </td>
                </tr>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">LootBox Cost</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {`${dec(getv(base, `tokmap.${token}.price`), decnmap[token])} ${token}`}
                    </p>
                  </td>
                </tr>
                <tr className={Array2D_Tablecn.tr_cn}>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">Total</p>
                  </td>
                  <td className={Array2D_Tablecn.td_cn}>
                    <p className="whitespace-pre-wrap">
                      {`${dec(getv(base, `tokmap.${token}.price`) * qty, decnmap[token])} ${token}`}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="fr-cc my-2">
              {loading ? (
                <>
                  <Loader01c size="s" />
                </>
              ) : (
                <>
                  {nils(err) && (
                    <Tag
                      onClick={clickpayment}
                      className={twMerge(
                        "fr-cc gap-2 bg-acc4/40 text-white transform -skew-x-12",
                        token == "WETH"
                          ? "bg-purple-500/50"
                          : token == "DEZ"
                            ? "bg-acc4/40"
                            : "",
                      )}
                    >
                      <TokenIcon token={token} />
                      <span>Pay Now</span>
                    </Tag>
                  )}
                </>
              )}
            </div>
          </div>
        </Card>
      </PopUp>
    </>
  );
};

const decnmap = {
  WETH: 4,
  DEZ: 1,
};
const BuyFor = ({ base, token }) => {
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const enablebuy = nils(countdown) || iso() > countdown;
  return (
    <div className="fc-cc resp-text--1">
      <span className="font-digi resp-text-1">
        {dec(getv(base, `tokmap.${token}.price`), decnmap[token])}
      </span>

      {enablebuy && auth == true && !nils(base) ? (
        <>{base.available > 0 ? <Buy {...{ base, token }} /> : null}</>
      ) : (
        <div class="fr-sc">
          <TokenIcon token={token} />
          <span>{token}</span>
        </div>
      )}
    </div>
  );
};

const pagebgimg =
  "https://cdn.dnaracing.run/fbike-factions-agents/base/portalpagebg.png";
const InfoSection = () => {
  const raritychance = [
    ["Common", 68],
    ["Legendary", 2],
  ];

  const [qobase] = useQueries([
    q_factions_lootbox_baseinfo({
      staleTime: 1 * 60 * 1e3,
      refetchInterval: 1 * 60 * 1e3,
    }),
  ]);
  const base = useMemo(() => {
    if (!qissuccesss(qobase)) return null;
    return getv(qobase, "data.result");
  }, [qobase.dataUpdatedAt]);

  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;
  const { enablebuy } = useMintContext();
  const laycon = useLayoutContext();

  return (
    <div className="">
      <div className="grid sm:grid-cols-2 md:grid-cols-6 max-w-[98vw] w-full">
        <div className="sm:col-span-2 md:col-span-3 realtive w-full mx-auto">
          <div className="fc-cc w-full flex-wrap relative">
            <div
              className={twMerge(
                "md:w-[15rem] md:h-[15rem]  md:top-[2rem] md:left-[2rem]",
                "xs:w-[8rem] xs:h-[12rem]  xs:top-[1rem] xs:left-[1rem]",
                "absolute translate-x-[50%] translate-y-[50%] bg-acc4 z-[0] blur-3xl",
              )}
            ></div>
            <div className="mint-box-container xs:mt-[50px] md:mt-[133px]">
              <div
                className={twMerge(
                  "md:top-[-5rem] md:h-[12rem]",
                  "xs:top-[-2rem] xs:h-[6rem]",
                  "img-obey-cont absolute",
                )}
              >
                <img src={boximg} />
              </div>
              <div className="xs:h-[5rem] md:h-[7rem]"></div>
              <p className="resp-text-2 font-digi resp-my-4">Agent Mint</p>
              <div className="fr-cc w-full">
                <div className=""></div>
                {qissuccesss(qobase) && (
                  <div className="grid grid-cols-2 resp-gap-2 w-full">
                    <BuyFor {...{ base, token: "WETH" }} />
                    <BuyFor {...{ base, token: "DEZ" }} />
                  </div>
                )}
                <div className=""></div>
              </div>

              {auth == false && (
                <Tag
                  onClick={() => {
                    laycon.open_loginpop();
                  }}
                  className=" mt-4 mb-2 px-4 py-2 bg-acc4/80 text-black fr-cc gap-2"
                >
                  <Img
                    className={"xs:w-[0.9rem] md:w-[1.5rem]"}
                    img={metamaskimg}
                  />
                  <span>Connect Wallet</span>
                </Tag>
              )}

              {enablebuy ? (
                <>
                  {auth == true && qissuccesss(qobase) && (
                    <>
                      {base.available > 0 ? (
                        <></>
                      ) : (
                        <Tag className="bg-red-400 tex-black rounded-md italic ">
                          Out of Stock!!!
                        </Tag>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="fc-cc p-2 font-digi text-[1.2rem]">
                    <p>Starts in</p>
                    <p className="">{from_time_mini(countdown, true)}</p>
                  </div>
                </>
              )}
              <div className="fr-sc w-full">
                <div className="flex-1"></div>
                <p className="text-acc4 italic font-bold my-2 resp-text--1">
                  {base?.available} boxes left
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:col-span-2 md:col-span-3 p-4  w-full mx-auto">
          <div className="fc-cc w-full flex-wrap">
            <div className="h-[2rem]"></div>
            <div className="resp-text-2 font-digi">Buy a Factions Agent</div>

            <div className="p-2">
              <p className="mid-head-text">
                <span className="resp-text-0 font-digi">
                  Rarity Chance for each faction
                </span>
              </p>
              <table className=" border-b border-slate-500  xs:w-[12rem] md:min-w-[20rem]  resp-my-2 resp-text--1">
                <thead>
                  <tr>
                    <th className="border-b border-acc4/40 text-acc4 px-2 md:pl-6">
                      Type
                    </th>
                    <th className="border-b border-acc4/40 text-acc4 px-2 md:pl-6">
                      #Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {raritychance.map((r) => {
                    return (
                      <tr>
                        {r.map((d) => (
                          <td className="border-b border-slate-600 resp-px-2 md:pl-6 py-1 text-left xs:min-w-[2rem] md:min-w-[5rem] resp-text--1">
                            {d}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const boximg = "https://i.imgur.com/e4SYcLa.png";
export const ClosedBoxes = ({ closed_n }) => {
  const { mmaccount: vault } = useMetaMaskContext();
  const mintcon = useMintContext();
  const { upd_closed_n } = mintcon;

  const [resp, set_resp] = useState({});
  const [popup, set_popup] = useState(false);
  const [loading, set_loading] = useState(false);

  const openbox = async () => {
    set_loading(true);
    const lootcon = await FactionsAgentsBox.get_contract();
    let resp = await lootcon.openLootbox(1, {});
    console.log(resp);
    await q_que_txn({
      service: "factionsagentsbox",
      hash: resp.hash,
    }).queryFn();
    await cdelay(5000);
    await cdelay(5000);

    await upd_closed_n();
    set_loading(false);
  };

  // useEffect(() => {
  //   console.log(resp);
  // }, [resp]);

  if (closed_n == 0)
    return (
      <>
        <p className="text-acc4 resp-text-0">
          No Closed LootBoxes owned currently
        </p>
      </>
    );
  return (
    <div className=" rounded-md fr-cc w-max">
      <Card
        className={twMerge("p-4 bg-r2dark/70 pl-[2rem] overflow-hidden w-max")}
      >
        <div className="fr-cc">
          <div className="relative xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] mx-auto ">
            <div className="absolute animate-fade-in xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] bg-acc4 z-[5] blur-3xl"></div>
            <Img
              img={boximg}
              className={
                "absolute xs:w-[6rem] md:w-[12rem] xs:h-[6rem] md:h-[12rem] z-[10]"
              }
            />
          </div>
          <div className="p-4">
            <p className="text-acc4 font-digi  resp-text-2">x {closed_n}</p>
          </div>
        </div>
        <div className="fr-sc my-4">
          <div className="flex-1"></div>
          {loading && <Loader01c size="s" />}
          <Tag
            onClick={() => {
              if (loading) return;
              openbox();
            }}
            className="bg-acc4 -skew-x-12 text-black font-digi"
          >
            Open 1 Box
          </Tag>
        </div>
        {!nils(resp.err) && (
          <div className="fr-sc my-4">
            <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
          </div>
        )}
      </Card>
    </div>
  );
};

const get_faction_agent_img = (a) =>
  nils(a?.img)
    ? null
    : `https://cdn.dnaracing.run/fbike-factions-agents/${a.img.toLowerCase()}.png`;

const ViewLootBoxNFTs = ({ a: ag, tx, idx }) => {
  let m = getv(tx, `connects_post.mintmap.${idx}`) || {};
  const [a, set_a] = useState(ag);
  useEffect(() => {
    const fn = async () => {
      let resp = await q_factions_lootbox_agentinfo_early({
        agid: ag.agid,
        id: tx.id,
      }).queryFn();
      if (resp.status == "success") set_a(resp.result);
    };
    fn();
  }, []);
  return (
    <div className="my-2 border-b border-acc4/40 py-2 resp-text--1">
      <div class="fr-sc my-2 resp-gap-1">
        <div class="flex-1"></div>
        {nils(m.hash) ? (
          <>
            <Loader01c size="s" />
            <span className="text-acc4 resp-text-0">Awaiting Mint...</span>
          </>
        ) : (
          <>
            <Link
              className="fr-sc resp-gap-1"
              target="_blank"
              to={polymaintxnlink(m.hash)}
            >
              <Img
                className={twMerge(
                  "xs:w-[1.2rem] xs:h-[1.2rem]",
                  "md:w-[2rem] md:h-[2rem]",
                )}
                img={polychainimg}
              />
              <span className="text-purple-300 resp-text-0">
                {m.hash.slice(0, 5)}..
              </span>
            </Link>
            <span className="resp-text-0">Minted on {iso_format(m.date)}</span>
          </>
        )}
      </div>

      <div class="fr-cc my-1 resp-gap-2 mx-auto">
        <div class="aspect-[2/1] w-[15rem] xs:w-[10rem]">
          <Img img={get_faction_agent_img(a)} />
        </div>
        <div className="fc-cc resp-gap-2">
          <span className="resp-text-1 text-acc4 font-digi">
            #{a.agid} {a.name}
          </span>
          <span className="text-acc4 uppercase">{a.faction} Faction</span>
          {m.minted !== true && (
            <span>Agent should show up shortly once its minted</span>
          )}
        </div>
      </div>
    </div>
  );
};

const TxRow = ({ tx }) => {
  const [nftspopup, set_nftspopup] = useState(false);

  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const amt = getv(tx, "connects.amt");
  const token = getv(tx, "connects.token");
  const td_amt = (
    <div className="fr-sc resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <TokenIcon size="xs" token={token} />
      <span>{nils(amt) ? "--" : dec(amt ?? 0, decnmap[token])}</span>
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polytxnlink(hash)} className="xs:w-[1rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");
  const td_inout = (
    <>
      {tx.type == "mint" && (
        <Tag className="bg-purple-500/20 text-white">{qty} boxes Minted</Tag>
      )}
      {tx.type == "buy" && (
        <Tag className="bg-purple-500/20 text-white">{qty} boxes Minted</Tag>
      )}
      {tx.type == "open" && (
        <Tag className="bg-yellow-500/20 text-white">{qty} boxes Opened</Tag>
      )}
    </>
  );

  const ls = getv(tx, "connects_post.lootids");

  const td_view = (
    <>
      {tx.type == "open" && (
        <>
          <Tag
            onClick={() => {
              set_nftspopup(true);
            }}
            className="bg-acc4/40 transform -skew-x-12 text-white gap-1 fr-cc"
          >
            <span>view agent</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </Tag>

          <PopUp
            wrapcn={twMerge("top-[5rem] left-[50%]")}
            innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
            openstate={nftspopup}
            overlayclose={false}
          >
            <Card
              className={
                "xs:w-[90vw] card-basic-bg card-grad-border md:w-[50rem] max-w-[95rem] relative"
              }
            >
              <div className="fr-cc">
                <div className="flex-1"></div>
                <Tag
                  onClick={() => set_nftspopup(false)}
                  className={twMerge(
                    "fc-cc rounded-full bg-red-500 ",
                    "absolute md:right-[-1rem] md:top-[-1rem] md:w-[2rem] md:h-[2rem]",
                    "absolute xs:right-[-0.5rem] xs:top-[-0.5rem] xs:w-[1rem] xs:h-[1rem]",
                  )}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Tag>
              </div>
              {(getv(tx, "connects_post.sels") || []).map((a, idx) => {
                return <ViewLootBoxNFTs {...{ key: a.agid, a, tx, idx }} />;
              })}
            </Card>
          </PopUp>
        </>
      )}
    </>
  );

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc4"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};

const Transactions = ({ txns }) => {
  return (
    <Card className={"w-max mx-auto card-basic-bg card-grad-border"}>
      {_.isEmpty(txns) ? (
        <p>No Transactions Yet</p>
      ) : (
        <table className={Array2D_Tablecn.table_cn}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

function MintFactionAgentsPage() {
  const enablebuy = nils(countdown) || iso() > countdown;

  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const [closed_n, set_closed_n] = useState(null);
  const upd_closed_n = async () => {
    try {
      if (!auth) return null;
      let lootcon = await FactionsAgentsBox.get_contract();
      let bal = await lootcon.accountOwns(vault);
      bal = Number(bal);
      set_closed_n(bal);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      upd_closed_n();
    }, 3000);
  }, [auth]);

  const [qotxns] = useQueries([
    q_factions_lootbox_txns(
      { vault },
      {
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);
  const txns = useMemo(() => {
    upd_closed_n();
    if (qissuccesss(qotxns)) return getv(qotxns, "data.result");
    return [];
  }, [qotxns.dataUpdatedAt]);

  const mintcon = {
    vault,
    enablebuy,
    upd_closed_n,
    closed_n,
    qotxns,
    txns,
  };
  return (
    <MintContext.Provider value={mintcon}>
      <Helmet>
        <title>Core Mint || DNA Racing</title>
      </Helmet>
      <div className="relative">
        <div className="xs:hidden md:block max-h-[calc(800px)] h-[calc(100vh-9rem)] ">
          <div className="">
            <div
              style={{
                backgroundImage: `url(${pagebgimg})`,
              }}
              className="hero-section"
            >
              <div className="gradient-overlay"></div>
              <div className="h-[3rem]"></div>
              <div className="html-overlay relative">
                <div className="home-container">
                  <InfoSection />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xs:block md:hidden">
          <div className="home-container">
            <InfoSection />
          </div>
        </div>
        {auth && (
          <div className="home-container">
            <div className="h-[2rem]"></div>
            <LootBoxesList />
            <div className="h-[2rem]"></div>
            <div className="h-[2rem]"></div>
          </div>
        )}
      </div>
    </MintContext.Provider>
  );
}

const LootBoxesList = () => {
  const mintcon = useMintContext();
  const { closed_n, txns } = mintcon;
  return (
    <div className="">
      <div className="fr-sc my-2 gap-2">
        <p className="text-acc4 font-digi resp-text-2">LootBoxes</p>
      </div>
      <p className="text-acc4 italic  resp-text-1">Closed Boxes</p>
      <ClosedBoxes closed_n={closed_n} />

      <div className="h-[2rem]"></div>

      <p className="text-acc4 italic  resp-text-1">Transactions</p>
      <Transactions {...{ txns }} />
    </div>
  );
};

export default MintFactionAgentsPage;
