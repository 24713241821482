import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import {
  Card,
  Img,
  LazyQueryLoad,
  Tag,
  tablecn,
} from "../components/utilityComps.js";
import { twMerge } from "tailwind-merge";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso,
  iso_format,
  jstr,
  nano,
  nils,
  toeth,
  tofeth,
} from "../utils/utils.js";
import { metamaskimg, polychainimg } from "../utils/links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useAppContext, useNowContext } from "../App.js";
import { useQueries } from "react-query";
import {
  iserr,
  polytxnlink,
  q_bikeburn_eliglist,
  q_bikeburn_est_reward,
  q_bikeburn_queadd,
  q_bikeburn_txns,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { Loader01c } from "../components/anims.js";
import _ from "lodash";
import { elementmap } from "../utils/cn_map.js";
import { PopUp, PopupCloseBtn } from "../components/popup.js";
import { BikeBurner } from "../contracts/BIkeBurner/BikeBurner.js";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs.js";
import { contractAddress_list } from "../contracts/contracts.js";
import { polygon } from "thirdweb/chains";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { useLayoutContext } from "../components/Layout.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";

const creditsimg = "https://i.imgur.com/a6t6OwQ.png";

const BurnContext = createContext({});
export const useBurnContext = () => useContext(BurnContext);

const EligHRow = ({ h }) => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;
  const { hid } = h;

  const [pop, set_pop] = useState(false);
  const [popresp, set_popresp] = useState({});
  const [popdata, set_popdata] = useState({});

  const auto_burn_core = async () => {
    await set_popresp({ loading: true, msg: `Burning Core #${hid}` });
    try {
      await cdelay(1000);
      let burncon =
        aumode === "thirdweb"
          ? await BikeBurner.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await BikeBurner.get_contract();

      let bikecon =
        aumode === "thirdweb"
          ? await t3_asset_signer("core", null, null)
          : await mm_asset_signer("core", null, null);

      let apv = await bikecon.isApprovedForAll(vault, burncon.contractAddress);
      await cdelay(1000);
      console.log("apv", apv);
      if (apv !== true) {
        let tx = null;
        if (aumode === "thirdweb") {
          set_popresp({
            loading: true,
            msg: "Approving Core for Burn",
          });
          tx = await t3_contract_call(
            "core",
            "setApprovalForAll",
            [burncon.contractAddress, true],
            "txn",
            true,
            { active_account },
          );
        } else {
          set_popresp({
            loading: true,
            msg: "Approving Core for Burn.. please check Metamask",
          });

          tx = await bikecon.setApprovalForAll(burncon.contractAddress, true);
          set_popresp({
            loading: true,
            msg: "Approving Core for Burn..",
          });
          await tx.wait();
        }
        await cdelay(5000);
      }

      let tx = null;
      if (aumode === "thirdweb") {
        set_popresp({
          loading: true,
          msg: "Requesting Burning Core..",
        });
        await cdelay(1 * 1e3);
        tx = await t3_contract_call(
          "bikeburner",
          "requestBurn",
          [hid],
          "txn",
          true,
          { active_account },
        );
      } else {
        set_popresp({
          loading: true,
          msg: "Requesting Burning Core.. please check Metamask",
        });
        await cdelay(1 * 1e3);
        tx = await burncon.requestBurn(hid);
        await cdelay(5000);

        set_popresp({
          loading: true,
          msg: "Requesting Burning Core...",
        });
        await cdelay(1000);
        tx = await tx.wait();
        console.log("burnresp", tx);
      }

      let hash = tx.hash;
      if (nils(hash)) throw new Error("couldnot catch burn txn hash");

      let resp = await q_bikeburn_queadd({
        hash,
        vault: vault,
        ext: { hid },
      }).queryFn();
      if (iserr(resp)) throw new Error(resp.err);

      await set_popresp({
        ...popresp,
        loading: false,
        msg: "Core Burned Requested... should update in a few minutes",
      });
      await cdelay(5000);
      await closepopup();
    } catch (err) {
      console.log(err);
      let errtxt = null;
      if (!nils(err.reason)) {
        errtxt =
          err.reason.length > 100
            ? err.reason.slice(0, 100) + "..."
            : err.reason;
      } else {
        errtxt = err.message;
      }
      set_popresp({ ...popresp, err: errtxt });
      await cdelay(4000);
    }
  };

  const [qorew] = useQueries([
    q_bikeburn_est_reward({ hid }, { enabled: pop }),
  ]);
  const rew = useMemo(() => getv(qorew, "data.result"), [qorew.dataUpdatedAt]);

  const closepopup = () => {
    set_pop(false);
    set_popdata({});
    set_popresp({});
  };

  return (
    <>
      <tr key={hid} className={twMerge("thintdrowp4")}>
        <td>
          <span>#{hid}</span>
        </td>
        <td>
          <span className="font-digi italic text-acc4">{h.name}</span>
        </td>
        <td>
          <span>{_.capitalize(h.type)}</span>
        </td>
        <td>
          <div className="fr-sc resp-gap-2">
            <span>F{h.fno}</span>
            <FontAwesomeIcon
              className={twMerge("resp-text--2", elementmap[h.element].text)}
              icon={elementmap[h.element].icon}
            />
          </div>
        </td>
        <td>
          {!nils(h.req) ? (
            <Tag className="text-red-300">Requesteed</Tag>
          ) : (
            <Tag
              onClick={() => {
                set_pop(true);
              }}
              className="bg-red-500 text-white"
            >
              Burn
            </Tag>
          )}
          <PopUp
            {...{
              openstate: pop,
              overlayclose: false,
              onClose: closepopup,
            }}
          >
            <Card
              className={twMerge(
                "max-w-[98vw] w-[40rem] mx-auto card-basic-bg resp-text--2",
              )}
            >
              <div className="fr-sc">
                <div className="flex-1"></div>
                <PopupCloseBtn closepopup={closepopup} />
              </div>
              <p className="text-center text-acc4 my-2 font-digi resp-text--1">
                <span>#{hid}</span>
                <span className="mx-2">{h.name}</span>
              </p>

              <div
                className={twMerge(
                  "fr-sc rounded-md resp-gap-2",
                  !nils(popresp.msg) ? "border border-green-400 resp-p-2" : "",
                  !nils(popresp.err) ? "border border-red-400 resp-p-2" : "",
                )}
              >
                {popresp.loading && <Loader01c size="s" />}
                {popresp.msg && (
                  <span className="text-green-400">{popresp.msg}</span>
                )}
                {popresp.err && (
                  <span className="text-red-400">{popresp.err}</span>
                )}
              </div>

              <LazyQueryLoad qo={qorew} result={rew}>
                {!nils(rew) && (
                  <div>
                    {rew.invalid ? (
                      <div className="text-red-300 fc-cc resp-gap-2 cannot">
                        <p>Not Allowed to burn this Core</p>
                        <p>{rew.invalid_msg}</p>
                      </div>
                    ) : (
                      <>
                        <div className="p-2 w-max mx-auto">
                          {/*  <table className={tablecn.table}>
                            <tbody>
                              <tr className="thintdrowp4">
                                <td className="text-acc4">Mint</td>
                                <td className="">
                                  30% refund of DNA Fee{" "}
                                  <span className="text-acc4">
                                    {dec(getv(rew, "rewardar.0.mintfee"), 0)}
                                  </span>
                                </td>
                                <td>
                                  {dec(getv(rew, "rewardar.0.reward"), 2)}{" "}
                                  {getv(rew, "token")}
                                </td>
                              </tr>
                              <tr className="thintdrowp4">
                                <td className="text-acc4">Races</td>
                                <td className="">
                                  4.05% of game profits on{" "}
                                  <span className="text-acc4">
                                    {getv(rew, "rewardar.1.races_n")} Races
                                  </span>
                                </td>
                                <td>
                                  {dec(getv(rew, "rewardar.1.reward"), 2)}{" "}
                                  {getv(rew, "token")}
                                </td>
                              </tr>
                              <tr className="thintdrowp4">
                                <td className="text-acc4">Total</td>
                                <td className=""></td>
                                <td className="font-digi text-orange-300 resp-text--1">
                                  {dec(getv(rew, "reward"), 2)}{" "}
                                  {getv(rew, "token")}
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
                          <div className="text-acc4 font-digi fr-cc resp-gap-1">
                            <span>Total Reward</span>
                            <span className="text-orange-400">
                              {rew.reward} {rew.token}
                            </span>
                          </div>
                        </div>
                        <div className="fr-sc w-full ">
                          <div className="flex-1"></div>
                          {popresp.loading == true ? (
                            <>
                              <Loader01c size="s" extcn={"bg-red-500"} />
                              <span className="text-acc4 font-digi resp-text--1">
                                Burning...
                              </span>
                            </>
                          ) : (
                            <Tag
                              onClick={auto_burn_core}
                              className={twMerge("bg-red-500 -skew-x-12")}
                            >
                              Confirm Burn
                            </Tag>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
              </LazyQueryLoad>
            </Card>
          </PopUp>
        </td>
      </tr>
    </>
  );
};

export const EligibleList = () => {
  const bcon = useBurnContext();
  const { elighs } = bcon;

  const eligibles = useMemo(
    () => elighs?.filter((h) => nils(h.req)),
    [jstr(elighs)],
  );

  return (
    <Card className={twMerge("card-basic-bg")}>
      {_.isEmpty(eligibles) ? (
        <p className="text-center text-yellow-300">No Eligible Cores</p>
      ) : (
        <table className={twMerge(tablecn.table, "")}>
          <tbody className={twMerge("thintdrowp4 resp-text--2")}>
            {eligibles?.map((h) => {
              return <EligHRow key={h.hid} h={h} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

const ActHRow = ({ h }) => {
  const mmcon = useMetaMaskContext();
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;

  const { now } = useNowContext();
  const { hid, req } = h;

  const [pop, set_pop] = useState(false);
  const [popresp, set_popresp] = useState({});
  const [popdata, set_popdata] = useState({});

  const closepopup = () => {
    set_pop(false);
    set_popdata({});
    set_popresp({});
  };

  const auto_revoke_core = async () => {
    await set_popresp({ loading: true, msg: `Revoking Core #${hid}` });
    try {
      await cdelay(1000);
      let burncon =
        aumode === "thirdweb"
          ? await BikeBurner.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await BikeBurner.get_contract();
      let bikecon =
        aumode === "thirdweb"
          ? await t3_asset_signer("core", null, null)
          : await mm_asset_signer("core", null, null);

      let tx = null;
      set_popresp({
        loading: true,
        msg: "Revoking Burning Core...",
      });
      if (aumode === "thirdweb") {
        tx = await t3_contract_call(
          "bikeburner",
          "revokeRequest",
          [hid],
          "txn",
          true,
          { active_account },
        );
      } else {
        await burncon.revokeRequest(hid);
        await cdelay(1000);
        tx = await tx.wait();
      }
      console.log("burnresp", tx);

      let hash = tx.hash;
      if (nils(hash)) throw new Error("couldnot catch burn txn hash");

      let resp = await q_bikeburn_queadd({
        hash,
        vault: vault,
        ext: { hid },
      }).queryFn();
      if (iserr(resp)) throw new Error(resp.err);

      await set_popresp({
        ...popresp,
        loading: false,
        msg: "Core Burned Request has been Revoked... should update in a few minutes",
      });
      await cdelay(5000);
      await closepopup();
    } catch (err) {
      console.log(err);
      let errtxt = null;
      if (!nils(err.reason)) {
        errtxt =
          err.reason.length > 100
            ? err.reason.slice(0, 100) + "..."
            : err.reason;
      } else {
        errtxt = err.message;
      }
      set_popresp({ ...popresp, err: errtxt });
      await cdelay(4000);
    }
  };
  return (
    <>
      <tr className={twMerge("thintdrowp4")}>
        <td>
          <span>#{hid}</span>
        </td>
        <td>
          <span className="font-digi italic text-acc4">{h.name}</span>
        </td>
        <td>
          <span>{_.capitalize(h.type)}</span>
        </td>
        <td>
          <div className="fr-sc resp-gap-2">
            <span>F{h.fno}</span>
            <FontAwesomeIcon
              className={twMerge("resp-text--2", elementmap[h.element].text)}
              icon={elementmap[h.element].icon}
            />
          </div>
        </td>
        <td>
          <div className="fc-ss">
            <div className="fr-sc resp-gap-1 text-orange-300">
              <span className="">{req.reward}</span>
              <span className="">{req.token}</span>
            </div>
            <p className="text-yellow-300">
              {nano(now) > nano(req.to_burn_at)
                ? `In Burner`
                : `Burning in ${from_time_mini(req.to_burn_at)}`}
            </p>
          </div>
        </td>
        <td>
          <div className="fr-sc">
            {popresp?.loading == true ? (
              <Loader01c extcn="bg-orange-500" size="s" />
            ) : (
              <Tag
                // onClick={() => auto_revoke_core()}
                onClick={() => set_pop(true)}
                className={twMerge(
                  "bg-orange-500/60 font-digi -skew-x-12 resp-text--3",
                )}
              >
                Revoke Request
              </Tag>
            )}
          </div>
          <PopUp openstate={pop} overlayclose={false} onClose={closepopup}>
            <Card
              className={twMerge(
                "max-w-[98vw] w-[40rem] mx-auto card-basic-bg resp-text--2",
              )}
            >
              <div className="fr-sc">
                <div className="flex-1"></div>
                <PopupCloseBtn closepopup={closepopup} />
              </div>
              <p className="text-center text-acc4 my-2 font-digi resp-text--1">
                Revoke Request
              </p>
              <p className="text-center text-acc4 my-2 font-digi resp-text--1">
                <span>#{hid}</span>
                <span className="mx-2">{h.name}</span>
              </p>

              <div
                className={twMerge(
                  "fr-sc rounded-md resp-gap-2",
                  !nils(popresp.msg) ? "border border-green-400 resp-p-2" : "",
                  !nils(popresp.err) ? "border border-red-400 resp-p-2" : "",
                )}
              >
                {popresp.loading && <Loader01c size="s" />}
                {popresp.msg && (
                  <span className="text-green-400">{popresp.msg}</span>
                )}
                {popresp.err && (
                  <span className="text-red-400">{popresp.err}</span>
                )}
              </div>

              <div className="fr-sc w-full ">
                <div className="flex-1"></div>
                {popresp.loading == true ? (
                  <>
                    <Loader01c size="s" extcn={"bg-red-500"} />
                    <span className="text-acc4 font-digi resp-text--1">
                      Revoking request
                    </span>
                  </>
                ) : (
                  <Tag
                    onClick={auto_revoke_core}
                    className={twMerge("bg-orange-500 -skew-x-12")}
                  >
                    Confirm Revoke
                  </Tag>
                )}
              </div>
            </Card>
          </PopUp>
        </td>
      </tr>
    </>
  );
};

export const ActiveRequests = () => {
  const bcon = useBurnContext();
  const { elighs } = bcon;

  const acts = useMemo(
    () => elighs?.filter((h) => !nils(h.req)),
    [jstr(elighs)],
  );

  return (
    <Card className={twMerge("card-basic-bg")}>
      {_.isEmpty(acts) ? (
        <p className="text-center text-yellow-300">No Active Requests</p>
      ) : (
        <table className={twMerge(tablecn.table, "")}>
          <tbody className={twMerge("thintdrowp4 resp-text--2")}>
            {acts?.map((h) => {
              return <ActHRow key={h.hid} h={h} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

export const Transactions = () => {
  let bcon = useBurnContext();
  let { txns = [], hmap = {}, vmap = {} } = bcon;

  return (
    <Card className={twMerge("card-basic-bg")}>
      <table className={twMerge(tablecn.table, "")}>
        <tbody className={twMerge("thintdrowp4 resp-text--2")}>
          {txns.map((t) => {
            let c = getv(t, "connects");
            let h = getv(hmap, c.hid);
            let v = getv(vmap, c.reqby);
            return (
              <tr className="thintdrowp4">
                <td className="">
                  <Tag
                    redirect={polytxnlink(t.id)}
                    className="fr-sc text-purple-300 resp-gap-1"
                  >
                    <div className="xs:w-[0.6rem] lg:w-[1.2rem]">
                      <Img img={polychainimg} />
                    </div>
                    <span>{t.id.slice(0, 5)}...</span>
                  </Tag>
                </td>
                <td className="">
                  <span className="text-orange-300">
                    {_.chain(t.type)
                      .split("_")
                      .map(_.capitalize)
                      .join(" ")
                      .value()}
                  </span>
                </td>
                <td>
                  <div className="fc-ss resp-text--3">
                    <span className="text-acc4 font-digi">
                      #{c.hid}-{h?.name}
                    </span>
                    <span className="text-acc4">By {v}</span>
                  </div>
                </td>
                <td>
                  {t.type == "burned" && (
                    <>
                      <span className="text-green-300">
                        +{getv(c, "reward")} {c.token}
                      </span>
                    </>
                  )}
                </td>
                <td>
                  <span className="resp-text--3">{iso_format(t.date)}</span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const CreditsBalance = () => {
  const bcon = useBurnContext();
  const { bgcbalance, upd_balance } = bcon;
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  return (
    <>
      <Tag
        className={twMerge(
          "xs:rounded-full lg:rounded-full fr-sc gap-1 resp-text--1 xs:px-2 lg:px-2",
          "border border-acc4 text-acc4",
        )}
      >
        <div className="xs:w-[1rem] xs:h-[1rem] xs:w-[2rem] xs:h-[2rem]">
          <Img img={creditsimg} />
        </div>
        {!auth ? (
          <span>--</span>
        ) : auth && nils(bgcbalance) ? (
          <div className="spin-anim">
            <FontAwesomeIcon icon={faRefresh} />
          </div>
        ) : (
          <span>{dec(bgcbalance, 2)}</span>
        )}
      </Tag>
    </>
  );
};

export const BikeBurnPage = () => {
  const aucon = useAuthContext();
  const { auth, vault, aumode } = aucon;

  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;
  const laycon = useLayoutContext();

  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const qen = !nils(vault) && auth == true;

  const [qo_txns, qo_eliglist] = useQueries([
    q_bikeburn_txns({ vault }, { enabled: qen }),
    q_bikeburn_eliglist({ vault }, { enabled: qen }),
  ]);

  const txns = useMemo(
    () => getv(qo_txns, "data.result.txns"),
    [qo_txns.dataUpdatedAt],
  );
  const hmap = useMemo(
    () => getv(qo_txns, "data.result.hmap"),
    [qo_txns.dataUpdatedAt],
  );
  const vmap = useMemo(
    () => getv(qo_txns, "data.result.vmap"),
    [qo_txns.dataUpdatedAt],
  );
  const elighs = useMemo(
    () => getv(qo_eliglist, "data.result"),
    [qo_eliglist.dataUpdatedAt],
  );

  const [tab, set_tab] = useState(psearch?.tab ?? "elig");
  useEffect(() => {
    let rem = { tab };
    upd_psearch(rem);
  }, [tab]);

  const [bgcbalance, set_bgcbalance] = useState(null);
  const upd_balance = async () => {
    try {
      let con =
        aumode === "thirdweb"
          ? await t3_asset_signer("bgc", null, null)
          : await mm_asset_signer("bgc");
      let bal = await con.balanceOf(vault);
      bal = tofeth(bal);
      set_bgcbalance(bal);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (!auth) return;
    upd_balance();
    let interval = setInterval(upd_balance, 1 * 60 * 1e3);
    return () => clearInterval(interval);
  }, [auth]);

  const bcon = {
    qo_txns,
    txns,
    hmap,
    vmap,

    qo_eliglist,
    elighs,

    bgcbalance,
    upd_balance,
  };
  return (
    <BurnContext.Provider value={bcon}>
      <div className="h-page">
        <div className="max-w-[98vw] w-[80rem] mx-auto">
          <div className="h-[3rem]"></div>
          <p className="text-center text-acc4 font-digi resp-text-2 fr-cc resp-gap-2 my-4">
            <FontAwesomeIcon className="text-orange-400" icon={faFire} />
            <span>Bike Burner</span>
          </p>

          {auth && !nils(vault) ? (
            <>
              <div className="my-2 fr-sc mx-auto">
                <div className="flex-1"></div>
                <CreditsBalance />
              </div>

              <div className="fr-cc">
                {[
                  ["Eligible", "elig"],
                  ["Active Requests", "reqs"],
                  ["Transactions", "txns"],
                ].map(([txt, _tab]) => {
                  let active = tab == _tab;
                  return (
                    <Tag
                      key={_tab}
                      onClick={() => set_tab(_tab)}
                      className={twMerge(
                        "font-digi resp-text--1 transition duration-300 ",
                        active
                          ? "bg-acc4/60 -skew-x-12 "
                          : "hover:text-acc4 text-white",
                      )}
                    >
                      {txt}
                    </Tag>
                  );
                })}
              </div>

              <div className="my-2 bg-acc4/40 h-[0.2rem] rounded-md mx-auto w-[30rem] max-w-[98vw]"></div>

              {tab == "elig" && (
                <div>
                  {/* <p className="my-2 text-acc4 font-diti resp-text--1">
                    Eligible{" "}
                  </p> */}
                  <LazyQueryLoad qo={qo_eliglist} result={elighs}>
                    <EligibleList />
                  </LazyQueryLoad>
                </div>
              )}
              {tab == "reqs" && (
                <div>
                  {/* <p className="my-2 text-acc4 font-diti resp-text--1">
                    Requests{" "}
                  </p> */}
                  <ActiveRequests />
                </div>
              )}
              {tab == "txns" && (
                <div>
                  {/* <p className="my-2 text-acc4 font-diti resp-text--1">
                    Transactions{" "}
                  </p> */}
                  <Transactions />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="fc-cc resp-gap-4">
                <p className="text-center text-acc4 font-digi resp-text-2">
                  Please connect your wallet to continue
                </p>

                <Tag
                  onClick={() => {
                    laycon.open_loginpop();
                  }}
                  className={twMerge(
                    "resp-text--1 fr-sc resp-gap-1 bg-acc4/40 -skew-x-12",
                  )}
                >
                  <span>Login</span>
                </Tag>
              </div>
            </>
          )}

          <div className="h-[5rem]"></div>
        </div>
      </div>
    </BurnContext.Provider>
  );
};
