import React, { useEffect, useMemo, useState } from "react";
import { Card, Tag } from "../components/utilityComps.js";
import {
  InstallMM,
  tokenacc_locpath,
  tokenaumode_locpath,
  tokenauth_locpath,
  token_locpath,
  useMetaMaskContext,
} from "../wrappers/MetaMaskWrapper.js";
import { Loader01c, Spinner } from "../components/anims.js";
import { metamaskimg } from "../utils/links.js";
import { cdelay, nils } from "../utils/utils.js";
import { useAppContext } from "../App.js";
import { PopupCloseBtn } from "../components/popup.js";
import { ThridWebLogin, ThridWebLoginSection } from "./ThirdWebLogin.js";

function Login({ onclose = () => {} }) {
  const { history, path } = useAppContext();
  const mmcon = useMetaMaskContext();
  const { auth, auth_refresh } = mmcon;

  const [logloading, set_logloading] = useState(false);
  const [logerr, set_logerr] = useState(null);

  const { try_mm_connect } = mmcon;
  useEffect(() => {
    try_mm_connect();
    localStorage.removeItem(token_locpath);
    localStorage.removeItem(tokenacc_locpath);
    localStorage.removeItem(tokenaumode_locpath);
    localStorage.removeItem(tokenauth_locpath);
  }, []);

  /* const [account] = useMemo(() => {
    if (!mmcon.mm_connected) return null;
    let acc = window.ethereum.selectedAddress;
    return [acc];
  }, [window?.ethereum?.selectedAddress]); */

  const start_login = async () => {
    if (mmcon.mm_connected == false) return;
    set_logloading(true);
    try {
      let account = await mmcon.request_metamask_login();
      if (nils(account)) throw new Error("metamask window was closed abruptly");

      let jwtacc = localStorage.getItem(tokenacc_locpath);
      if (jwtacc == account) {
      } else {
        await mmcon.gen_token();
      }
      await cdelay(1000);
      await auth_refresh();
      await cdelay(2000);
      set_logloading(false);
    } catch (err) {
      set_logerr(err.message);
    }
  };

  useEffect(() => {
    if (auth == true) {
      onclose();
    }
  }, [auth, logloading]);

  return (
    <Card
      className={
        "mt-[10vh] bg-r2dark w-[40rem] max-w-[98vw] relative shadow-lg shadow-acc0/60"
      }
    >
      <div className="fr-sc">
        <div className="flex-1"></div>
        <PopupCloseBtn closepopup={onclose} />
      </div>
      <p className="resp-my-2 resp-text-3 text-acc0 font-digi text-center">
        Login
      </p>
      {/*       {mmcon.mm_connected !== true ? (
        <InstallMM />
      ) : logloading == true ? (
        <>
          <Loader01c />
          <p className="text-acc0 text-center resp-text-1">
            Loading Account...
          </p>
        </>
      ) : (
        <>
          <p className="text-center resp-text-1">Please Login using Metamask</p>
          <div className="fr-cc resp-my-4">
            <Tag
              onClick={() => {
                start_login();
              }}
              className="bg-lig"
            >
              <img
                className="object-contain w-[6rem]"
                src={metamaskimg}
                alt=""
              />
            </Tag>
          </div>
        </>
      )} */}

      {/*       <hr /> */}
      <div class="fc-cc">
        <p className="resp-text-1 text-acc4 font-digi">Thirdweb</p>
        <ThridWebLoginSection
          postlogin={async () => {
            await auth_refresh();
          }}
        />
      </div>

      {!nils(logerr) && (
        <div className="resp-p-2 resp-px-4 border border-red-400 text-red-400 rounded-md">
          <span>{logerr}</span>
        </div>
      )}
    </Card>
  );
}

export default Login;
