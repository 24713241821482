import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  q_bikeinfo,
  q_vaultbikes,
  q_vaultinfo,
  useStepQuery,
} from "../queries/queries.js";
import { useQueries } from "react-query";
import { getv, jparse, jstr, nils } from "../utils/utils.js";
import _ from "lodash";
import { set_state_ob } from "../components/input.js";
import { useMetaMaskContext } from "./MetaMaskWrapper.js";

export const AccountContext = createContext();
export const useAccountContext = () => useContext(AccountContext);

const def_acc_config = {
  mode: "all",
  vaulthconfs: {},
};

export default function AccountWrapper(props) {
  const { vault } = useMetaMaskContext();
  const [qo_vaultinfo, qo_vaultbikes] = useQueries([
    q_vaultinfo({ vault }, { enabled: !nils(vault) }),
    q_vaultbikes({ vault }, { enabled: !nils(vault) }),
  ]);
  const vdoc = useMemo(() => {
    let vaultdoc = getv(qo_vaultinfo, "data.result");
    return vaultdoc;
  }, [qo_vaultinfo.dataUpdatedAt]);
  const hids = useMemo(() => {
    let vaultbikes = getv(qo_vaultbikes, "data.result") ?? [];
    return vaultbikes;
  }, [qo_vaultbikes.dataUpdatedAt]);

  const accon = {
    vault,

    vdoc,
    hids,

    qo_vaultinfo,
    qo_vaultbikes,
  };
  return (
    <AccountContext.Provider value={accon}>
      {props.children}
    </AccountContext.Provider>
  );
}
