import {
  faQuestion,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Array2D_Tablecn,
  Card,
  Img,
  InpText,
  Tag,
} from "../components/utilityComps";
import { metamaskimg } from "../utils/links";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import { Loader01c } from "../components/anims.js";
import { PopUp } from "../components/popup";
import { twMerge } from "tailwind-merge";
import { useQueries } from "react-query";
import {
  q_lootbox_base,
  q_lootbox_buy_box,
  q_lootbox_validate_purchase,
  q_lootbox_boxes,
  qiserr,
  qissuccess,
  qissuccesss,
} from "../queries/queries.js";
import { cdelay, dec, getv, nano, nils, to_time_mini } from "../utils/utils.js";
import { transfer_tokens } from "../queries/chainreq.js";
import _ from "lodash";
import {
  ClosedBoxes,
  LockedBoxes,
  MiniLootBox,
  OpenedBoxes,
} from "./LootBox.js";
import { Input } from "../components/input.js";

const MintContext = createContext({});
export const useMintContext = () => useContext(MintContext);

const Buy = ({ base }) => {
  const mmcon = useMetaMaskContext();
  const vault = mmcon.mmaccount;

  const { boxes, locks, qoboxes } = useMintContext();

  const [expand, set_expand] = useState(false);
  const [mode, set_mode] = useState(null);

  const [loading, set_loading] = useState(false);
  const [txnresp, set_txnresp] = useState(false);
  const [boxresp, set_boxresp] = useState(false);
  const [err, set_err] = useState(null);

  const [qty, set_qty] = useState(1);

  const [qvalidpur] = useQueries([
    q_lootbox_validate_purchase(
      { vault, qty },
      { enabled: true, staleTime: 2 * 60 * 1e3 },
    ),
  ]);

  const on_buyclick = async () => {
    set_expand(true);
    set_mode("qty");
  };

  const buy_fn = async () => {
    if (loading == true) return;
    set_mode("txn");

    await cdelay(100);
    set_loading(true);
    try {
      // call transfertokens
      let amt = base.cost * qty;
      amt = parseFloat(dec(amt, 6));

      let resp = await transfer_tokens({
        amt: amt,
        token: base.token,
        reciever: base.reciever,
      });

      // let resp = await new Promise(async (resolve, reject) => {
      //   setTimeout(() => {
      //     resolve({
      //       status: "success",
      //       transactionHash: `0x_bypass_admin_${nano()}`,
      //     });
      //   }, 5000);
      // });

      set_txnresp(resp);

      try {
        let txn_hash = resp.transactionHash;
        let boxresp = await q_lootbox_buy_box({
          vault,
          txn_hash,
          qty,
        }).queryFn();

        if (boxresp.status == "success") {
          set_mode("waitbox");
          set_loading(false);
          set_boxresp(boxresp);
        } else {
          throw new Error(boxresp.err);
        }
      } catch (err) {
        set_err(err.message);
      }
    } catch (err) {
      set_err(err.message);
      set_loading(false);
    }
  };

  // useEffect(() => {
  //   console.log("resp", { txnresp, boxresp });
  // }, [txnresp, boxresp]);

  if (expand)
    return (
      <>
        <PopUp
          openstate={true}
          overlayclose={false}
          wrapcn={twMerge("top-[5rem] left-[50%]")}
          innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
        >
          <Card className={"relative max-w-[98vw] w-[50rem]"}>
            {
              <div
                onClick={() => {
                  set_expand(false);
                  set_loading(false);
                  set_err(null);
                }}
                className="cursor-pointer absolute top-[-0.7rem] right-[-0.7rem] z-20 bg-red-500 rounded-full fc-cc w-[2rem] h-[2rem]"
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            }

            <p className="my-2 text-center">
              {mode == "txn" && "Please Confirm Transaction on Metamask"}
              {mode == "waitbox" && "Waiting for Confirmation"}
            </p>

            {loading && <Loader01c />}

            {mode == "qty" && (
              <>
                <div className="mx-auto w-max">
                  <div className="fr-cc gap-2">
                    <p>{mode == "qty" && "Select Quantity"}</p>
                    <InpText
                      id="inp_qty"
                      contprops={{ className: "w-max bg-dark" }}
                      inpprops={{ className: "w-[4rem]" }}
                      def_val={qty}
                      setter={() => {
                        let v = document.getElementById("inp_qty").value;
                        v = parseInt(v);
                        if (!nils(v) && _.inRange(v, 1, 100 + 1e-4)) {
                          set_qty(v);
                          set_err(null);
                        } else {
                          set_err(`Enter Quantity between 1 to 100`);
                        }
                      }}
                    />
                  </div>
                  <hr className="my-2" />
                  <table className={Array2D_Tablecn.table_cn}>
                    <tbody>
                      <tr className={Array2D_Tablecn.tr_cn}>
                        <td className={Array2D_Tablecn.td_cn}>
                          <p className="whitespace-pre-wrap">Quantity</p>
                        </td>
                        <td className={Array2D_Tablecn.td_cn}>
                          <p className="whitespace-pre-wrap">{qty} qty.</p>
                        </td>
                      </tr>
                      <tr className={Array2D_Tablecn.tr_cn}>
                        <td className={Array2D_Tablecn.td_cn}>
                          <p className="whitespace-pre-wrap">LootBox Cost</p>
                        </td>
                        <td className={Array2D_Tablecn.td_cn}>
                          <p className="whitespace-pre-wrap">
                            {dec(base.cost, 6)} {base.token}
                          </p>
                        </td>
                      </tr>
                      <tr className={Array2D_Tablecn.tr_cn}>
                        <td className={Array2D_Tablecn.td_cn}>
                          <p className="whitespace-pre-wrap">Total</p>
                        </td>
                        <td className={Array2D_Tablecn.td_cn}>
                          <p className="whitespace-pre-wrap">
                            {qvalidpur.isLoading ? (
                              <Loader01c size="s" />
                            ) : qissuccesss(qvalidpur) ? (
                              `${dec(base.cost * qty, 6)} ${base.token}`
                            ) : (
                              "--"
                            )}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {qiserr(qvalidpur) && (
                    <p className="text-red-400 whitespace-pre-wrap my-2">
                      {`purchase not allowed:\n${qiserr(qvalidpur)}`}
                    </p>
                  )}
                  {qissuccesss(qvalidpur) &&
                    getv(qvalidpur, "data.result.valid") == true && (
                      <div className="fr-cc">
                        <Tag
                          onClick={() => {
                            buy_fn();
                          }}
                          className={twMerge("bg-acc0 text-black")}
                        >
                          Pay Now
                        </Tag>
                      </div>
                    )}
                </div>
              </>
            )}

            {mode == "txn" && <></>}

            {mode == "waitbox" && (
              <>
                <p className="whitespace-pre-wrap w-full text-yellow-400">
                  {getv(boxresp, "result.msg")}
                </p>
                <div className="my-2"></div>
              </>
            )}

            {err && (
              <p className="p-2 px-4 text-red-400 border-2 border-red-400 rounded-md my-2">
                {err}
              </p>
            )}
          </Card>
        </PopUp>
      </>
    );
  else
    return (
      <Tag
        onClick={() => {
          on_buyclick();
        }}
        className={twMerge("bg-acc0 text-black")}
      >
        Buy Now
      </Tag>
    );
};

const BoxesSection = () => {
  const raritychance = [
    ["F1", "METAL", 210],
    ["F2", "METAL", 375],
    ["F3", "FIRE", 500],
    ["F4", "FIRE", 625],
    ["F5", "EARTH", 800],
    ["F6", "EARTH", 900],
    ["F7", "WATER", 1300],
    ["F8", "WATER", 1800],
  ];

  const [qobase] = useQueries([q_lootbox_base({})]);
  const base = useMemo(() => {
    if (!qissuccesss(qobase)) return null;
    return getv(qobase, "data.result");
  }, [qobase.dataUpdatedAt]);

  const mmcon = useMetaMaskContext();
  const { enablebuy } = useMintContext();

  return (
    <div className="">
      <div className="grid sm:grid-cols-2 md:grid-cols-6 max-w-[98vw] w-full">
        <div className="sm:col-span-2 md:col-span-3 realtive w-full mx-auto">
          <div className="fc-cc w-full flex-wrap relative">
            <div className="absolute w-[15rem] h-[15rem]  top-[2rem] left-[2rem] translate-x-[50%] translate-y-[50%] bg-acc0 z-[0] blur-3xl"></div>
            <div className="mint-box-container ">
              <div className="img-obey-cont rotate-[-20deg] absolute top-[-5rem] h-[12rem]">
                <img src="https://i.imgur.com/5LJPt3M.png" />
              </div>
              <div className="h-[7rem]"></div>
              <p className="head-text">NFT Mint</p>
              <div className="fr-cc">
                <div className=""></div>
                {qissuccesss(qobase) && (
                  <>
                    <div className="fc-cc gap-0 px-4 border-x-2 border-acc0/30">
                      <p className="leading-4	text-white text-[1.4rem] font-mon font-bold ">
                        {enablebuy ? dec(base.cost, 3) : "0.1"}
                        <span className="text-[1rem]"> {base.token}</span>
                      </p>
                      <p className="leading-4	 base-text">price</p>
                    </div>
                  </>
                )}
                <div className=""></div>
              </div>
              {enablebuy ? (
                <>
                  {mmcon.auth == false &&
                    (mmcon.loading ? (
                      <Tag className="bg-dark text-acc0 fr-cc gap-2">
                        <Loader01c size="s" />
                        <span>loading...</span>
                      </Tag>
                    ) : (
                      <Tag
                        onClick={() => {
                          mmcon.login();
                        }}
                        className=" mt-4 mb-2 px-4 py-2 bg-acc0/80 text-black fr-cc gap-2"
                      >
                        <Img className={"w-[1.5rem]"} img={metamaskimg} />
                        {mmcon.loading ? (
                          <span>loading...</span>
                        ) : (
                          <span>Connect Wallet</span>
                        )}
                      </Tag>
                    ))}
                  {mmcon.auth == true && qissuccesss(qobase) && (
                    <>
                      {base.rem_supply > 0 ? (
                        <Buy {...{ base }} />
                      ) : (
                        <Tag className="bg-red-400 tex-black rounded-md italic ">
                          Out of Stock!!!
                        </Tag>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Tag className="bg-acc0 text-black my-2 px-4 font-digi">
                    Coming Soon...
                  </Tag>
                  <div className="my-2">
                    <p className="text-center">
                      {to_time_mini("2023-08-16T01:00:00Z", true)}
                    </p>
                  </div>
                </>
              )}
              <div className="fr-sc w-full">
                <div className="flex-1"></div>
                <p className="text-acc0 italic font-bold my-2">
                  {base?.rem_supply} boxes left
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:col-span-2 md:col-span-3 p-4  w-full mx-auto">
          <div className="fc-cc w-full flex-wrap">
            <div className="h-[2rem]"></div>
            <div className="head-text">Buy A NFT Mint Box </div>

            <div className="p-2">
              <p className="mid-head-text">
                <span className="text-[1.4rem]">Rarity Chance</span>
              </p>
              <table className=" border-b border-slate-500 min-w-[20rem] my-2">
                <thead>
                  <tr>
                    <th className="border-b border-acc0/40 text-acc0 px-2 md:pl-6">
                      GENERATION
                    </th>
                    <th className="border-b border-acc0/40 text-acc0 px-2 md:pl-6">
                      ELEMENT
                    </th>
                    <th className="border-b border-acc0/40 text-acc0 px-2 md:pl-6">
                      Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {raritychance.map((r) => {
                    return (
                      <tr>
                        {r.map((d) => (
                          <td className="border-b border-slate-600 px-2 md:pl-6 py-1 text-left min-w-[5rem]">
                            {d}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="base-text">
                F1 assets have, on average, more strength than F8+ assets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function MintPage() {
  const enablebuy = true;

  const mmcon = useMetaMaskContext();
  const { mmaccount: vault } = mmcon;
  const [qoboxes] = useQueries([
    q_lootbox_boxes(
      { vault },
      {
        enabled: !nils(vault) && enablebuy,
        staleTime: 10 * 1e3,
        refetchInterval: 10 * 1e3,
      },
    ),
  ]);
  const boxes = useMemo(() => {
    if (!qissuccesss(qoboxes)) return [null, null];
    let boxes = getv(qoboxes, "data.result");
    return boxes;
  }, [qoboxes.dataUpdatedAt]);

  const mintcon = {
    enablebuy,
    vault,
    qoboxes,
    boxes,
  };
  return (
    <MintContext.Provider value={mintcon}>
      <div className="min-h-[calc(100vh-9rem)]">
        <div className="xs:hidden md:block">
          <div className="hero-section">
            <div className="gradient-overlay"></div>
            <div className="h-[3rem]"></div>
            <div className="html-overlay relative">
              <div className="home-container">
                <BoxesSection />
              </div>
            </div>
          </div>
        </div>
        <div className="xs:block md:hidden">
          <div className="home-container">
            <BoxesSection />
          </div>
        </div>
      </div>
      {enablebuy && (
        <div className="home-container">
          <div className="h-[2rem]"></div>
          <LootBoxesList />
          <div className="h-[2rem]"></div>
          <div className="h-[2rem]"></div>
        </div>
      )}
      <div className="home-footer"></div>
    </MintContext.Provider>
  );
}

const LootBoxesList = () => {
  const mintcon = useMintContext();
  const { qoboxes, boxes } = mintcon;

  return (
    <div className="">
      <div className="fr-sc my-2 gap-2">
        <p className="text-acc0 font-digi text-[2rem]">LootBoxes</p>
        {qoboxes.isLoading && <Loader01c size="s" />}
      </div>

      {qissuccesss(qoboxes) && (
        <>
          <p className="text-acc0 font-mon italic text-[1.5rem] mt-[1rem]">
            Locked
          </p>
          <LockedBoxes {...{ lock_s: boxes.lock_s }} />

          <p className="text-acc0 font-mon italic text-[1.5rem] mt-[1rem]">
            Closed
          </p>
          <ClosedBoxes {...{ closed_n: boxes.closed_n }} />

          <p className="text-acc0 font-mon italic text-[1.5rem] mt-[1rem]">
            Opened
          </p>
          <OpenedBoxes {...{ open_s: boxes.open_s }} />
        </>
      )}
    </div>
  );
};

export default MintPage;
