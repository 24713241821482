import { useEffect, useState, createContext, useContext, useMemo } from "react";
import _ from "lodash";
import { createThirdwebClient } from "thirdweb";
import {
  ConnectButton,
  useConnect,
  ConnectEmbed,
  AutoConnect,
  useActiveWallet,
  useActiveAccount,
  useDisconnect,
} from "thirdweb/react";
import { inAppWallet, createWallet } from "thirdweb/wallets";
import { LoginPayload, VerifyLoginPayloadParams } from "thirdweb/auth";
import { preAuthenticate, getUserEmail } from "thirdweb/wallets/in-app";
import { Tag, tokenimgmap } from "../components/utilityComps";
import { cdelay, getv, nils } from "../utils/utils";
import { q_auth_get_nonce, q_auth_verify_nonce } from "../queries/queries";
import {
  tokenacc_locpath,
  tokenaumode_locpath,
  token_locpath,
  tokenauth_locpath,
} from "../wrappers/MetaMaskWrapper";
import { ThirdwebProvider } from "thirdweb/react";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { polygon } from "thirdweb/chains";
import { contractAddress_list } from "../contracts/contracts";

export const thirdweb_clientid = "3f6f539b44aa7d0bd853551b08a34beb";
export const thirdweb_client = createThirdwebClient({
  clientId: thirdweb_clientid,
});

const ThirdWebLoginContext = createContext({});
export const useThirdWebLoginContext = () => useContext(ThirdWebLoginContext);

const socials = inAppWallet({
  auth: {
    options: ["google", "email", "facebook", "apple"],
  },
});
const wallets = [
  socials,
  createWallet("com.coinbase.wallet"),
  createWallet("me.rainbow"),
  createWallet("io.metamask"),
  createWallet("io.zerion.wallet"),
  createWallet("org.uniswap"),
  createWallet("com.trustwallet.app"),
];

const ThirdWebLoginWrapperInner = (props) => {
  const [connected, set_connected] = useState(false);
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();

  const switch_chain = async (wallet) => {
    let resp = await wallet.getChain();
    // console.log("switch_chain:get", resp);
    if (resp.name !== "Polygon") {
      let resp = await wallet.switchChain(polygon);
      // console.log("switch_chain:switch", resp);
    }
  };

  const active_wallet = useActiveWallet();
  const active_account = useActiveAccount();
  useEffect(() => {
    // console.log("thirdweb:active", active_wallet, active_account);
  }, [active_wallet, active_account]);

  const vault = useMemo(() => {
    if (nils(active_account)) return null;
    return active_account.address.toLowerCase();
  }, [active_account]);

  const init_customui_login = async ({ strategy }) => {
    try {
      await connect(async () => {
        const wallet = inAppWallet();
        // console.log("wallet", wallet);
        let resp = await wallet.connect({
          client: thirdweb_client,
          strategy,
        });
        // console.log("wallet resp", resp);
        if (!resp) return;
        await gen_token(resp);
      });
    } catch (err) {
      // console.log("init_google_login err", err);
    }
  };

  const gen_token = async (acc) => {
    let vault = acc.address.toLowerCase();

    let non = await q_auth_get_nonce({ vault });
    let nonce = getv(non, "result.nonce");
    // console.log("nonce", nonce);
    if (nils(nonce)) throw new Error("couldn't fetch nonce");

    let tosign = nonce;
    let signed_data = await acc.signMessage({
      message: tosign,
      address: vault,
    });
    let resptok = await q_auth_verify_nonce({ signed_data, vault });
    // console.log("resptok", resptok);
    if (resptok?.status == "error") throw new Error(resptok.err);

    let token = getv(resptok, "result.token");
    // console.log("token", token);

    localStorage.setItem(token_locpath, token);
    localStorage.setItem(tokenacc_locpath, vault);
    localStorage.setItem(tokenaumode_locpath, "thirdweb");
    return token;
  };

  const logout = async () => {
    // console.log("thirdweb:logout", active_wallet, active_account);
    if (active_wallet) {
      await disconnect(active_wallet);
      window.auth_logout();
    }
  };
  useEffect(() => {
    if (nils(active_wallet)) return;
    window.t3_logout = logout;
  }, [active_wallet]);

  const aumode = localStorage.getItem(tokenaumode_locpath);
  useEffect(() => {
    let aumode = localStorage.getItem(tokenaumode_locpath);
    if (connected && aumode !== "thirdweb") {
      localStorage.setItem(tokenaumode_locpath, "thirdweb");
    }
  }, [connected, aumode]);

  const polytokens = _.chain([
    {
      address: contractAddress_list.dez,
      symbol: "DEZ",
      name: "DEZ",
      icon: tokenimgmap["DEZ"],
    },
    {
      address: contractAddress_list.weth,
      symbol: "WETH",
      name: "WETH",
      icon: tokenimgmap["WETH"],
    },
    {
      address: contractAddress_list.bgc,
      symbol: "BGC",
      name: "Bike Credits",
      icon: tokenimgmap["BGC"],
    },
    {
      address: contractAddress_list.lc,
      symbol: "LC",
      name: "LC",
      icon: tokenimgmap["LC"],
    },
    {
      address: contractAddress_list.sgc,
      symbol: "SGC",
      name: "Skin Credits",
      icon: tokenimgmap["SGC"],
    },
  ])
    .keyBy("symbol")
    .value();

  const on_connect_fn = async (wallet) => {
    console.log("onConnect:wallet.id", wallet.id);
    set_connected(true);
    let acc = await wallet.getAccount();
    if (!acc) return;
    // let token = localStorage.getItem(token_locpath);
    // if (nils(token)) return;
    let exis_auth = localStorage.getItem(tokenauth_locpath);
    // console.log("exis_auth", exis_auth);
    if (exis_auth !== "true") {
      await gen_token(acc);
    }

    let token = localStorage.getItem(token_locpath);
    await cdelay(1000);
    await window.auth_refresh();
  };

  const tcon = {
    gen_token,
    init_customui_login,
    logout,
    active_wallet,
    active_account,
    thirdweb_client,
    thirdweb_clientid,
    vault,

    connected,
    set_connected,
    polytokens,
    on_connect_fn,
  };

  return (
    <ThirdWebLoginContext.Provider value={tcon}>
      {props.children}
    </ThirdWebLoginContext.Provider>
  );
};

export const ThirdWebLoginWrapper = (props) => {
  return (
    <ThirdwebProvider>
      <ThirdWebLoginWrapperInner {...props} />
    </ThirdwebProvider>
  );
};

export const ThridWebLoginSection = ({
  btntext = "connect without wallet",
  postlogin = null,
}) => {
  const {
    active_wallet,
    active_account,
    thirdweb_client,
    thirdweb_clientid,
    wallets,
    gen_token,
    logout,

    connected,
    set_connected,
    polytokens,
    on_connect_fn,
  } = useThirdWebLoginContext();

  return (
    <>
      <div class="fr-sc">
        <ConnectButton
          client={thirdweb_client}
          wallets={wallets}
          chain={polygon}
          connectButton={{
            label: btntext,
            // displayBalanceToken: {
            //   137: polytokens["DEZ"].address,
            // },
          }}
          connectModal={{ size: "compact" }}
          onConnect={async (wallet) => {
            await on_connect_fn(wallet);
            if (postlogin) await postlogin();
          }}
          onDisconnect={() => {
            set_connected(false);
            console.log("onDisconnect");
            localStorage.removeItem(token_locpath);
            localStorage.removeItem(tokenacc_locpath);
            localStorage.removeItem(tokenaumode_locpath);
            localStorage.removeItem(tokenauth_locpath);
          }}
          supportedTokens={{
            137: _.values(polytokens),
          }}
        />

        {false && !_.isEmpty(active_account) && (
          <Tag
            className="bg-r2dark text-white font-digi "
            onClick={() => {
              logout();
            }}
          >
            <FontAwesomeIcon
              className="text-acc0 resp-text-1"
              icon={faSignOut}
            />
          </Tag>
        )}
      </div>
    </>
  );
};
