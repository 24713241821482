import React, { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../App.js";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  q_tp_giftcard_txns,
  q_que_txn,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useMetaMaskContext } from "../wrappers/MetaMaskWrapper.js";
import {
  cdelay,
  dec,
  getv,
  iso,
  iso_format,
  jstr,
  nils,
} from "../utils/utils.js";
import {
  Card,
  Img,
  PopupCloseBtn,
  Tag,
  tablecn,
  tokenimgmap,
} from "../components/utilityComps.js";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faRefresh,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { CircularLoader, Loader01c } from "../components/anims.js";
import colors from "tailwindcss/colors";
import { twMerge } from "tailwind-merge";
import TP_GiftCard from "../contracts/TP_GiftCard/TP_GiftCard";
import { metamaskimg, polychainimg } from "../utils/links.js";
import { PopUp } from "../components/popup.js";
import { Link } from "react-router-dom";
import {
  ElementTag,
  FNoTag,
  GenderTag,
  TypeTag,
} from "../components/ShortComps.js";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { polygon } from "thirdweb/chains";
import {
  t3_contract_call,
  t3_wait_for_txn,
} from "../contracts/contract_funcs.js";
import { useLayoutContext } from "../components/Layout.js";
import { useAuthContext } from "../wrappers/AuthWrapper.js";

const TP_GiftCardContext = React.createContext({});
const useTP_GiftCardContext = () => React.useContext(TP_GiftCardContext);

const boxtypes = [10, 50, 100, 500, 1000];
const boxtypemap = _.chain(boxtypes)
  .map((boxtype, index) => {
    return {
      k: boxtype,
      boxtype,
      name: `TP ${boxtype} Gift Card`,
      img: `https://cdn.dnaracing.run/imgs/tp_giftcard_${boxtype}.png`,
    };
  })
  .keyBy("k")
  .value();

const ClosedBox = ({ boxtype }) => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  const { vault, auth, aumode } = aucon;
  const laycon = useLayoutContext();

  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;
  const bmap = boxtypemap[boxtype];

  const pcon = useTP_GiftCardContext();
  const { qo_txns } = pcon;

  const [resp, set_resp] = useState({});
  const [count_loading, set_count_loading] = useState(true);
  const [loading, set_loading] = useState(false);
  const [closed_n, set_closed_n] = useState(0);

  const update_closed = async () => {
    try {
      set_count_loading(true);
      const con =
        aumode === "thirdweb"
          ? await TP_GiftCard.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await TP_GiftCard.get_contract();
      let n = await con.accountOwns(vault, boxtype);
      set_count_loading(false);
      return set_closed_n(n);
    } catch (err) {
      console.log(err);
      set_count_loading(false);
      setTimeout(update_closed, 5 * 1e3);
    }
  };

  useEffect(() => {
    let interval = null;
    if (auth) {
      setTimeout(update_closed, 2 * 1e3);
      interval = setInterval(update_closed, 2 * 60 * 1e3);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [auth]);

  const [qty, set_qty] = useState(1);
  const open_box = async () => {
    try {
      set_loading(true);
      set_resp({});
      if (qty < 1) return;

      const con =
        aumode === "thirdweb"
          ? await TP_GiftCard.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await TP_GiftCard.get_contract();

      let resp = null;
      if (aumode === "thirdweb") {
        upd_psearch({ boxopentx: "waiting_hash" });
        resp = await t3_contract_call(
          "tp_giftcard",
          "openLootbox",
          [qty, boxtype],
          "txn",
          false,
          { active_account },
        );
        upd_psearch({ boxopentx: resp.hash });
        resp = await t3_wait_for_txn(resp.hash);
      } else {
        upd_psearch({ boxopentx: "confirm_metamask" });
        resp = await con.openLootbox(qty, boxtype);
        console.log(resp);
        resp = await resp.wait();
        console.log(resp);
      }

      if (nils(resp.hash)) throw new Error("Transaction failed");
      upd_psearch({ boxopentx: resp.hash });
      await q_que_txn({
        hash: resp.hash,
        vault,
        service: "tp_giftcard",
      }).queryFn();

      await cdelay(5000);
      set_loading(false);
      setTimeout(update_closed, 5 * 1e3);
      setTimeout(qo_txns.refetch, 40 * 1e3);
    } catch (err) {
      console.log(err);
      set_loading(false);
      set_resp({ err: err.message });
    }
  };

  return (
    <>
      {
        <div className=" rounded-md bg-r2reg/40 fr-cc w-max mx-auto">
          <Card
            className={twMerge(
              "resp-p-4 pl-[2rem] overflow-hidden w-max text-center bg-r2dark/20 mb-0",
            )}
          >
            <p className="font-digi resp-text-0 resp-my-2">{bmap.name}</p>
            <div className="fr-cc">
              <div className="relative xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] mx-auto ">
                <div
                  style={{
                    background: colors.teal[300],
                  }}
                  className={twMerge(
                    "absolute animate-fade-in xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] z-[5] blur-3xl",
                  )}
                ></div>
                <Img
                  img={bmap.img}
                  className={
                    "absolute xs:w-[4rem] md:w-[12rem] xs:h-[4rem] md:h-[12rem] z-[10]"
                  }
                />
              </div>
              <div className="p-4">
                {!auth ? (
                  <></>
                ) : count_loading ? (
                  <div className="fr-sc my-1">
                    <div className="spin-anim">
                      <FontAwesomeIcon
                        className="resp-text-1"
                        icon={faRefresh}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="text-acc0 font-digi resp-text-2">
                    x {closed_n}
                  </p>
                )}
              </div>
            </div>
            {closed_n > 0 ? (
              <div className="fr-sc resp-gap-2 my-1">
                {loading && <Loader01c size="s" />}
                <div className="flex-1"></div>
                {loading ? (
                  <>
                    <Tag className="-skew-x-12 fr-sc font-digi text-acc4 border-acc4">
                      <FontAwesomeIcon icon={faSpinner} className="spin-anim" />
                      <span>Redeeming...</span>
                    </Tag>
                  </>
                ) : (
                  <>
                    <div class="border border-r2lig rounded-md resp-text-1 flex flex-row justify-center items-stretch">
                      <FontAwesomeIcon
                        onClick={() => {
                          set_qty(Math.max(1, qty - 1));
                        }}
                        icon={faMinus}
                        className="p-1 cursor-pointer"
                      />
                      <span class="px-2 min-w-[3rem] text-center">{qty}</span>
                      <FontAwesomeIcon
                        onClick={() => {
                          set_qty(Math.min(qty + 1, closed_n));
                        }}
                        icon={faPlus}
                        className="p-1 cursor-pointer"
                      />
                    </div>

                    <Tag
                      onClick={() => {
                        if (loading) return;
                        open_box();
                      }}
                      className="-skew-x-12 text-white font-digi bg-acc4/40 cursor-pointer"
                    >
                      Redeem
                    </Tag>
                  </>
                )}
              </div>
            ) : (
              <div className="fr-sc my-1">
                <Tag className="opacity-5">-</Tag>
              </div>
            )}
            {!nils(resp.err) && (
              <div className="fr-sc my-4">
                <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
              </div>
            )}
          </Card>
        </div>
      }
    </>
  );
};

const ClosedBoxSection = () => {
  return (
    <div class="fr-cc resp-gap-4 w-full flex-wrap">
      {boxtypes.map((b) => {
        return <ClosedBox boxtype={b} />;
      })}
    </div>
  );
};

const TransactionTable = () => {
  const pcon = useTP_GiftCardContext();
  const { qo_txns } = pcon;
  const [txns = [], bmap = {}] = useMemo(() => {
    return [
      getv(qo_txns, "data.result.txns"),
      getv(qo_txns, "data.result.boxinfomap"),
    ];
  }, [qo_txns.dataUpdatedAt]);

  return (
    <Card
      className={twMerge(
        "bg-r2reg/40 mx-auto max-w-[95vw] w-[50rem] my-[2rem]",
      )}
    >
      <p className="resp-text-1 text-acc0 font-digi my-2">Transactions</p>

      <table className={twMerge(tablecn.table_cn, "w-max")}>
        <tbody>
          {txns.map((t, idx) => {
            let boxtype = getv(t, "connects.boxtype");
            return (
              <tr className={twMerge("thintdrowp4 resp-text--2")}>
                <td>
                  <Tag
                    redirect={polytxnidlink(t.id)}
                    className="fr-sc text-purple-300 resp-gap-1"
                  >
                    <div className="xs:w-[0.6rem] lg:w-[1.2rem]">
                      <Img img={polychainimg} />
                    </div>
                    <span>{t.id.slice(0, 5)}...</span>
                  </Tag>
                </td>

                <td>
                  {t.type == "mint" ? (
                    <Tag
                      className={twMerge(
                        "bg-orange-500/30 border border-transparent",
                        boxtype == 2 ? "border-pos1 italic" : "",
                      )}
                    >
                      MINTED {getv(t, "connects.qty")}
                      {" x "}
                      {boxtypemap[boxtype]?.name}
                    </Tag>
                  ) : t.type == "open" ? (
                    <Tag
                      className={twMerge(
                        "bg-green-500/30 border border-transparent",
                        boxtype == 2 ? "border-pos1 italic" : "",
                      )}
                    >
                      OPENED {getv(t, "connects.qty")}
                      {" x "}
                      {boxtypemap[boxtype]?.name}
                    </Tag>
                  ) : null}
                </td>
                <td>
                  <p className="text-right">{iso_format(getv(t, "date"))}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

export const TP_GiftCardPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const aucon = useAuthContext();
  const { auth, aumode } = aucon;
  const vault = useMemo(() => {
    if (!nils(psearch.vault)) return psearch.vault.toLowerCase();
    return aucon.vault;
  }, [psearch.vault, aucon.vault]);

  const laycon = useLayoutContext();

  const [qo_txns] = useQueries([
    q_tp_giftcard_txns({ vault }, { enabled: !nils(vault) }),
  ]);

  useEffect(() => {}, [psearch.boxopentx]);

  const pcon = { vault, qo_txns };

  return (
    <TP_GiftCardContext.Provider value={pcon}>
      <div className="h-page">
        <div className="mx-auto w-[60rem] max-w-[95vw]">
          <div className="h-[2rem]"></div>
          <p className="text-center text-acc0 font-digi resp-text-2">
            TP Gift Cards
          </p>
          <div className="h-[1rem]"></div>

          {!auth ? (
            <>
              <div className="fc-cc resp-gap-4">
                <p className="text-center text-acc0 font-digi resp-text-2">
                  Please connect your wallet to continue
                </p>

                <Tag
                  onClick={laycon.open_loginpop}
                  className={twMerge(
                    "resp-text--1 fr-sc resp-gap-1 bg-acc0/40 -skew-x-12",
                  )}
                >
                  <div className="xs:w-[1rem] lg:w-[2rem]">
                    <Img img={metamaskimg} />
                  </div>
                  <span>Connect Wallet</span>
                </Tag>
              </div>
            </>
          ) : (
            <>
              <ClosedBoxSection />

              <div class="h-[2rem] "></div>
              <hr />
              <div class="h-[2rem] "></div>

              {qo_txns.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qo_txns) ? (
                <p className="text-center text-red-300 resp-text--1">
                  {qiserr(qo_txns)}
                </p>
              ) : qissuccesss(qo_txns) &&
                _.isEmpty(getv(qo_txns, "data.result.txns")) ? (
                <p className="text-center text-yellow-300 resp-text--1">
                  No transactions found
                </p>
              ) : qissuccesss(qo_txns) &&
                !_.isEmpty(getv(qo_txns, "data.result.txns")) ? (
                <TransactionTable />
              ) : null}
            </>
          )}

          <div className="h-[5rem]"></div>
        </div>
      </div>
    </TP_GiftCardContext.Provider>
  );
};
